import * as actions from './SocketTypes'
import store from '../store'
import { AutoDialPlayParseBtn } from '../Calls/CallAction'

export const newMessage = (socket, data) => {
  localStorage.setItem("wasession", "true")
  socket.emit('accept-new-chat', data)
  return {
    type: actions.SET_NOTHING,
  }
}

export const wasessionhangup = (socket, data) => {
  socket.emit('wa-session-hangup', data)
  return {
    type: actions.SET_NOTHING,
  }
}
// export const sipStatus = (socket, data) => {
//   socket.emit('sipStatus', data)
//   return {
//     type: actions.SET_NOTHING,
//   }
// }

export const publishmessage = (socket, data) => {
  socket.emit('publish-message', data)
  return {
    type: actions.SET_NOTHING,
  }
}

export const initiateEvent=(data)=>{
  console.log(data,"uuuuuuuuuuuuuuuuuuuuuuuu")
  return (dispatch,getState)=>{
      return new Promise(async (resolve, reject)=>{
          let state = store.getState()
          
          dispatch({
            type: actions.INITIATE_EVENT,
            payload:data
          }) 
          resolve(data)
      })
      
  }
  

}
export const Outbound = (socket, data, other, missed) => {
 
  return (dispatch) => {
    let state = store.getState()
    dispatch(initiateEvent(true))
    if (state.socketAction.stop_event===0) {
      socket = state.background.websocketconnection
      const user = localStorage.getItem('username')
      const station = localStorage.getItem('extension')
      let tenant
      if (localStorage.getItem('config')) {
        tenant = JSON.parse(localStorage.getItem('config')).data.tenant_code
      }

      if (state.call.autodialplayparsebtn == true && state.call.autodialstatus == true && state.call.autodialdatas.length > 0) {
        dispatch(AutoDialPlayParseBtn())
      }

      localStorage.setItem('webrtcManual', 'true')
      let calldata = {
        action: 'Dial',
        agent: state.detail.user,
        agent_name: user,
        auto_dial_caller: false,
        call_type: 'MANUAL',
        campaign: state.detail.selected_campaign,
        lead_id: undefined,
        missed: false,
        list_id: '',
        meeting: false,
        phone_number: data,
        station: station,
        substitute: '',
        tenant_id: tenant,
        ticket_id: undefined,
      }

      if (other != undefined) {

        if (other.meeting) {
          calldata.meeting = true
          calldata.meeting_id = other.meetingid
        }

      }
      if (missed === true) {

        calldata.missed = true

      }
      let campaigndetails = JSON.parse(localStorage.getItem("campaigndetails"))
      let man_did = JSON.parse(localStorage.getItem("manualdid"))
      let strategy = campaigndetails.did_rotate_strategy
      if (strategy === "manual") {
        calldata.caller_id = man_did
      }
      socket.emit('initiate-call', calldata)
      // dispatch(initiateEsvent(false))
      return {
        type: actions.SET_NOTHING,
      }
      
    }
  }
}

export const dispo = (socket, data) => {
  socket.emit('hangup', data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const callForwardKick = (socket, data) => {
  socket.emit('xfer-cancel', data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const wraptime = (socket, data) => {
  console.log(socket,data,"77777777777777777777777777777777777777777")
  socket.emit('wrap', data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const callForward = (socket, data) => {
  socket.emit('transfer', data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const addconference = (socket, data) => {
  socket.emit(data.action, data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const conferenceKickout = (socket, data) => {
  socket.emit(data.action, data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const conferenceMute = (socket, data) => {
  socket.emit(data.action, data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const conferenceEnd = (socket, data) => {
  socket.emit(data.action, data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const queueLogin = (socket, data) => {
  socket.emit('queue-login', data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const queueLogout = (socket, data) => {
  socket.emit('queue-logout', data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const queuePause = (socket, data) => {
  socket.emit('queue-pause', data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const callRecording = (socket, data) => {
  socket.emit('record-mute', data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const terminatecall = (socket, data) => {
  socket.emit(data.action, data)
  return {
    type: actions.SET_NOTHING,
  }
}

export const logoutaction = (socket, data) => {
  socket.emit('user-logout', data)
  return {
    type: actions.SET_NOTHING,
  }
}

export const callGrab = (socket, data) => {
  socket.emit(data.action, data)
  return {
    type: actions.SET_NOTHING,
  }
}

export const forcelogout = (socket, data) => {
  socket.emit("cti-logout", data[0])
  socket.emit('queue-logout', data[1])
  socket.emit('user-logout', data[2])
 
  return {
    type: actions.SET_NOTHING,
  }

}
export const resetcall = (socket, data) => {
  socket.emit('check-call-status', data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const waTemplate = (socket, data) => {
  socket.emit(data.action, data)
  return {
    type: actions.SET_NOTHING,
  }
}
export const checkagentlive = (socket, data) => {
  socket.emit('ping', data)
  return {
    type: actions.SET_NOTHING,
  }
}


