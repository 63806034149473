import api from "../../utils/axios-util";
import {autodialnumberpopstate } from "../Calls/CallAction"

export const Subdispo = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/subdisposition?dispo_id=${payload}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
};

export const GetSubdispo = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
           
            await api
                .get(`/subdisposition?skip=0&take=10&subdispo_id=${payload}`)
                .then((response) => {
                   if (response.status == 200){
                    resolve(response.data.data);
                   }else{
                    resolve(204);
                   }
                   
                   
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};
export const Getdispo = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
           
            await api
                .get(`/disposition?skip=0&take=10&dispo_id=${payload}`)
                .then((response) => {
                   if (response.status == 200){
                    resolve(response.data.data);
                   }else{
                    resolve(204);
                   }
                   
                   
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};

export const CallActivity = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            let state = getState()
            await api
                .post(`/asterdialer/call/activity`,payload)
                .then((response) => {
                   if (response.status == 201){
                    if(state.call.autodialstatus == true && payload.callType == 'preview'){
                        dispatch(autodialnumberpopstate())
                    }
                   }
                   
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};


