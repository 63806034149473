import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Offcanvas from 'react-bootstrap/Offcanvas'
import clogo from '../assets/images/messageicon.svg'
import Profile from './profile'
import EventEmitter from '../utils/EventEmitter'
import clogo2 from '../assets/images/person.svg'
import { useNavigate } from 'react-router'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  newMessage
} from '../redux/SocketAction/SocketAction'
import {
  getnotification,
  update_read_unread,

} from '../redux/Chat-notification/Chat-notification-Action'
import format from '../utils/format-text'
import { Encode } from '../utils/EncodeDecode'

function Sidebar(props) {
  let agent = localStorage.getItem('user')
  const station = localStorage.getItem('extension')
  let tenant
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
    tenant = JSON.parse(localStorage.getItem('config')).data.tenant_code
  }
  let state_value = useSelector((state) => state)

  let navigate = useNavigate()
  let Dispatch = useDispatch()
  const [apiData, setapiData] = useState({ skip: 0, take: 10, agent: localStorage.getItem('user') })
  const [infinitScroll, setinfinitScroll] = useState({ limit: 7, more: true })
  const [show, setShow] = useState(false)
  const [notificationdata, setnotificationdata] = useState([])
  const [count, setcount] = useState(0)
  useEffect(() => {

    if (props.type === "notification") {
      getnotificationData('mount')
      event()
    }
    return () => {
      setinfinitScroll({ limit: 7, more: true })
    }
   

  }, [])

  function event() {
    EventEmitter.addListener('agent-message-response-notification', (data) => {
      if (data != "" && data != undefined) {
        getnotificationData('mount', "delete", data.session_id)

      }
    })
  }
  useEffect(() => {
    if (props.WaNotificationcount !== undefined) {
      if (Object.keys(props.WaNotificationcount).length > 0) {
        getnotificationData("mount")
      }
    }

  }, [props.WaNotificationcount])
  useEffect(() => {
    if (props.meetingNotificationcount !== undefined) {
      if (Object.keys(props.meetingNotificationcount).length > 0) {
        getnotificationData("mount")
      }
    }

  }, [props.meetingNotificationcount])
  useEffect(() => {
    if (props.announcementNotificationcount !== undefined) {
      if (Object.keys(props.announcementNotificationcount).length > 0) {
        getnotificationData("mount")
      }
    }

  }, [props.announcementNotificationcount])
  useEffect(() => {
    if (props.chatdrop !== undefined) {
      if (Object.keys(props.chatdrop).length > 0) {
        delete_notification([props.chatdrop.session_id])
      }
    }
  }, [props.chatdrop])



  function getnotificationData(data, action, id) {
    console.log("eeeeeeeeeeeeeeeeeeeeee",data, action, id)
    let payload
    if (data === 'mount') {
      setinfinitScroll({ limit: 7, more: true })
      setapiData({ skip: 0, take: 10, agent: localStorage.getItem('user') })
      setShow(false)
      payload = apiData
    }
    else {
      setapiData({ skip: 0, take: 10, agent: localStorage.getItem('user') })
      setShow(true)
    }
    if (data === "load_data") {
      payload = { skip: action, take: 10, agent: localStorage.getItem('user') }
      setapiData(payload)
    }

    Dispatch(getnotification(payload)).then((response) => {
      if (response.status === 200) {
        if (data === 'mount' || id==='open_notification') {
          setnotificationdata([].concat(response.data.data))
        } else {
          setnotificationdata(notificationdata.concat(response.data.data))
        }

        setcount(response.data.totalCount)
        if (action === "delete") {
          delete_notification([id], "wa", "notificationpop")
        }
      } else {
        setnotificationdata([])
        setcount(0)
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'notification',
      })
    })
  }
  const handleClose = () => {
    updateReadUnread()
    setinfinitScroll({ limit: 7, more: true })
    setShow(false)
  }

  const profilehandleClose = () => {
    setinfinitScroll({ limit: 7, more: true })
    setShow(false)
  }

  function updateReadUnread() {
    if (notificationdata.length > 0) {
      let array = []
      notificationdata.forEach((value) => {
        if (value.flag === 0) {
          array.push(value.record_id)
        }
      })
      Dispatch(update_read_unread({ id: array, action: 'readunread' })).then(
        (response) => {
          if (response.data.statusCode === 200) {
            setinfinitScroll({ limit: 7, more: true })
            setShow(false)
          }
          if (array.length === 0) {
            setinfinitScroll({ limit: 7, more: true })
            setShow(false)
          }
        },
      ).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'update',
          module: 'readed notification',
        })
      })
    } else {
      setinfinitScroll({ limit: 7, more: true })
      setShow(false)
    }
  }

  function dateformat(value) {
    let date = new Date(value)
    var seconds = Math.floor((new Date() - date) / 1000)

    var interval = seconds / 31536000

    if (interval > 1) {
      return Math.floor(interval) + ' year ago'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + ' month ago '
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + ' day ago'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + ' hour ago'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + ' minute ago'
    }
    return Math.floor(seconds) + ' second ago'
  }

  function delete_notification(data, domain, action) {

    Dispatch(update_read_unread({ id: data, action: 'delete' })).then(
      (response) => {
        if (response.data.statusCode === 200) {
         
          getnotificationData('mount')
        

        }

      },
    ).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'delete',
        module: 'notification',
      })
    })

  }

  function clearall() {
    let array = []
    notificationdata.forEach((value) => {
      array.push(value.record_id)
    })
    delete_notification(array)
  }

  function accept_notification(value) {
    if (value.channel === "whatsapp") {
      let payload = {
        tenant_id: tenant,
        station: station,
        agent: agent,
        session_id: value.record_id,
        phone_number: value.phone_number,
        sender_name: value.lead_name,
        campaign: localStorage.getItem("selectedCampaign"),
        channel:value.channel,
        custom_value:value.phone_number
      }
      Dispatch(newMessage(state_value.background.websocketconnection, payload))
      delete_notification([value.record_id], "wa")
    }

  }

  function visit_notification(value) {
    if (value.channel === "meeting") {
      navigate(`/meeting/${Encode(value.phone_number)}/${value.record_id}`)
      delete_notification([value.record_id])
    } else if (value.channel === "tickets") {
      navigate(`/tickets/${Encode(value.phone_number)}/${value.record_id}`)
      delete_notification([value.record_id])
    }

  }
  const fetchMoreData = () => {
    console.log(notificationdata.length, count, "222211212121")
    if (notificationdata.length >= count) {
      setinfinitScroll((set) => {
        set.more = false
        return {
          ...set,
        }
      })
    } else {
      setTimeout((v) => {
        getnotificationData('load_data', apiData.skip + apiData.take)
      }, 500)
    }
  }
  return (
    <div>
      {props.type === 'notification' && (
        <>
          <div onClick={()=>{getnotificationData("load_data",0,'open_notification')}} className="navbar-notification">
            <div className="count">{count}</div>
            <img
              className="navbar-notification-icon"
              src={clogo}
              alt="01Text"
            />
          </div>
          <Offcanvas
            placement={'end'}
            show={show}
            onHide={handleClose}
            backdrop={true}
          >
            <Offcanvas.Header
              closeButton={false}
              className="navbar-side-bar-header"
            >
              <Offcanvas.Title>Notifications</Offcanvas.Title>
              <span onClick={handleClose} className="material-icons navbar-close ">
                close
              </span>
            </Offcanvas.Header>
            <Offcanvas.Body className="navbar-Offcanvas-Body">
              <div
                id="chatDiv"
                style={{
                  height: '100%',
                  overflow: 'auto',

                }}
              >
                <InfiniteScroll
                  scrollableTarget="chatDiv"
                  dataLength={notificationdata.length} //This is important field to render the next data
                  next={fetchMoreData}
                  hasMore={infinitScroll.more}
                >

                  {notificationdata.length > 0 && show && (
                    <>
                      {notificationdata.map((value, index) => {
                        return (
                          <>
                        
                            <div
                              key={index}
                              className={
                                value.flag === 1
                                  ? 'navbar-side-bar-body navbar-side-bar-body-unread '
                                  : 'navbar-side-bar-body '
                              }
                            >
                             
                              <div className="navbar-side-bar-container">
                                <div
                                  className={
                                    value.type === 'readonly'
                                      ? 'navbar-side-bar-container1 navbar-side-bar-container1-sub '
                                      : 'navbar-side-bar-container1'
                                  }
                                >
                                  <Offcanvas.Title className="navbar-side-bar-title">
                                    <span className='navbar-notification-head'>
                                      {format(value.channel)} Notifications
                                    </span>
                                    <span className="navbar-sidebar-head-rht ">
                                      { value.flag === 1?" Readed":" Unread"}
                                    </span>
                                    <div className="navbar-sidebar-head-rht ">
                                      {dateformat(value.created_at)}
                                    </div>

                                  </Offcanvas.Title>
                                </div>

                               {localStorage.getItem('selectedPause')==='available'&& <div
                                  className={
                                    value.type === 'readonly'
                                      ? 'navbar-side-bar-container2 navbar-side-bar-container2-sub '
                                      : 'navbar-side-bar-container2'
                                  }
                                >
                                  {value.type === 'action' && (
                                    <span onClick={() => {
                                      accept_notification(value)
                                    }} className="custom-cursor material-icons navbar-side-bar-accept navbar-side-bar-button">
                                      check
                                    </span>
                                  )}
                                  {value.type === 'visit' && (
                                    <span onClick={() => {
                                      visit_notification(value)
                                    }} className="custom-cursor material-icons navbar-side-bar-view navbar-side-bar-button">
                                      visibility
                                    </span>
                                  )}
                                  <span
                                    onClick={() => {
                                      delete_notification([value.record_id])
                                    }}
                                    className={`${value.channel!=='whatsapp'? 'material-icons  navbar-side-bar-close navbar-side-bar-button':'material-icons disabledbutton navbar-side-bar-button navbar-side-bar-close'}`}
                                  >
                                    close
                                  </span>
                                </div>}
                              </div>

                              <OverlayTrigger
                                focus="hover"
                                placement="left"
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Header as="h3" className='navbar-side-bar-message-head'>
                                      {format(value.channel)} Notifications
                                    </Popover.Header>
                                    <Popover.Body>
                                      <div className="navbar-side-bar-message-popover">
                                        {format(value.message)}
                                      </div>
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <div className="navbar-side-bar-message">
                                  {format(value.message)}
                                </div>
                              </OverlayTrigger>
                            </div>
                          </>
                        )
                      })}
                    </>
                  )}
                  {notificationdata.length === 0 && show && (
                    <div className=" navbar-notification-nodata">
                      No Recent Notifications
                    </div>
                  )}

                </InfiniteScroll>
              </div>
            </Offcanvas.Body>
            {notificationdata.length > 0 && (
              <div className="navbar-sidebar-body2">
                <button
                  onClick={clearall}
                  className={`navbar-clearall navbar-side-bar-close  btn1 disabledbutton`}
                >
                  Clear All
                </button>
              </div>
            )}
          </Offcanvas>
        </>
      )}
      {props.type === 'profile' && (
        <>
          <div onClick={getnotificationData} className="navbar-notification">
            <p className="unread" />
            <img className="navbar-profile" src={clogo2} alt="01Text" />
          </div>


          <Offcanvas
            placement={'end'}
            show={show}
            onHide={profilehandleClose}
            backdrop={true}
          >
            <Offcanvas.Header
              closeButton={false}
              className="navbar-side-bar-header"
            >
              <Offcanvas.Title>Profile</Offcanvas.Title>
              <span onClick={profilehandleClose} className="material-icons navbar-close ">
                close
              </span>
            </Offcanvas.Header>
            <Offcanvas.Body className="navbar-Offcanvas-Body">
              <Profile closenav={profilehandleClose} />
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}

    </div>
  )
}

export default Sidebar