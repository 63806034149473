import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import { json, useNavigate } from 'react-router'
import { Col, Form, Row, Button, Card, Dropdown } from 'react-bootstrap'
import CallScreen from '../components/CallScreen'
import LeadTable from '../components/Table'
import { GetLeadTable, GetLeadSearch, getfilterdata ,GetColorCode , getfilter} from '../redux/actions/Asterleads'
import {
  autodialnumberpopstate,
  autodialdatas,
  callmodule, fetchcallaction,
  autodial,
  autocalldial
} from '../redux/Calls/CallAction'
import '../assets/css/Leads.css'
import waicon from '../assets/images/whatsapp.svg'
import { useDispatch, useSelector } from 'react-redux'
import { CustomformFields } from '../redux/actions/Customform'
import EventEmitter from '../utils/EventEmitter'
import Spinner from 'react-bootstrap/Spinner'
import Filter from '../components/filter'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Outbound } from '../redux/SocketAction/SocketAction'
import Format from '../utils/format-text'
import Dialogbox from '../components/Dialogbox'
import { getTemplate } from '../redux/actions/ChatActions'
import { waTemplate } from '../redux/SocketAction/SocketAction'
import { Encode } from '../utils/EncodeDecode'
import { Customfilter } from '../components/customfilter/index'
import ColorBar from '../components/Color-bar'
import { AsterAgentTableField, AsterAgentTableUpdate } from '../redux/actions/AsterHeader'
import DynamicField from '../components/DynamicField'
import { adminPermission } from '../utils/localstorage-provider'
function Leads() {
  let AppPermission
  const [wasession, setwasession] = useState("false")
  const [filterCheck,setFilterCheck] = useState('')
  const selectedCampaign = localStorage.getItem('selectedCampaign')
  const type = JSON.parse(localStorage.getItem('selectedCampaign_data')).type
  const user_id = localStorage.getItem('user')
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  let stateValue = useSelector((state) => state)
  let dispatch = useDispatch()
  let navigate = useNavigate()
  let webrtccallstatus = localStorage.getItem('webrtcManual')
  const [leaddetail, setleaddetail] = useState([])
  let [subfilter, setsubfilter] = useState(['new'])
  let dialer_interval = JSON.parse(localStorage.getItem("campaigndetails")).wrap_time 
  let [checked, setchecked] = useState(false)
  let [closefilterpop,setclosefilterpop]=useState(false)
  let selected_campaign = localStorage.getItem('selectedCampaign')
  let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
  let [dialerdiable, setdialerdiable] = useState('false')
  let [playparsebtn, setplayparsebtn] = useState(true)
  let [autodialtimer, setautodialtimer] = useState()
  let [autodialtimerstatus, setautodialtimerstatus] = useState(false)
  let [count, setcount] = useState(0)
  let [Filterfield, setFilterfield] = useState([])
  const [infinitScroll, setinfinitScroll] = useState({ more: true })
  const [skiptake, setskiptake] = useState({ skip: 0, take: 20 })
  const [apidatacount, setapidatacount] = useState(0)
  let [payload_data, setpayload] = useState({})
  let [filtershow, setfiltershow] = useState(false)
  let [search, setsearch] = useState("")
  let [wadata, setwadata] = useState([])
  let [List_of_Fav_Filter, setList_of_Fav_Filter] = useState([])
  let [current_Filter, setcurrent_Filter] = useState({})
  let [loader, setloader] = useState(false)
  let [dynamicHead,setDynamicHead] = useState(false)
  let [colorcode,setcolorcode] = useState([])
  const [popUpData, setpopUpData] = useState({
    open: false,
    Type: 'form_bar',
    field: [
      {
        type: 'SelectList',
        label: 'Whatsapp Template',
        placeholder: ' select message',
        required: true,
        model: 'whatsapp_template',
        option: [],
        multiple: false,
        value: '',
      },
    ],
    title: 'Whatsapp Template',
    Message: 'Selected Successfully',
    Butonstatus: 'Send',
  })
  let [isFilter , SetisFilter] = useState(false)
  let [FilterCondition , SetFilterCondition] = useState({})
  const [header, setheader] = useState([
    {
      Header: () => (
        <div >
          <span>Action</span>
        </div>
      ),
      accessor: 'action',
      isrender: false,
      Cell: ({ row }) => {
        return (
          <div className="lead_action">
            <span
              className="material-icons"
              style={{ color: '#3e98c7' }}
              onClick={() => leadView(row, 'leadView')}
            >
              info
            </span>
            {AppPermission.click_to_call && <span
              className={stateValue.background.oncall == false && localStorage.getItem("wasession") === "false" ? "material-icons phone" : "material-icons disabledbutton phone"}
              style={{ color: '#32AA52' }}
              onClick={() => { clickcall(row) }}
            >
              phone
            </span>}
            {AppPermission.wa_view &&
              <img
                onClick={() => { wapopup(row.original) }}
                className={"wa_icon"}
                src={waicon}
                alt="test"
              />


            }

          </div>
        )
      },
    },
  ])
  const handleSettingsClick = (value)=>{
    setclosefilterpop(true)
    setDynamicHead(!value)
  }
  const saveDynamicPopup = (data) =>{
    if(data===false){
      setDynamicHead(data)
      setheader(header)
      return
    }
    setDynamicHead(false)
    setloader(true)
    const payload ={
      fields:[data],
      module:'lead',
      campaign:selected_campaign,
      template:campaigninfo.template_name
    }
    dispatch(AsterAgentTableUpdate(payload,user_id)).then((response)=>{
      if(response.data.statusCode === 200){
        getDynamicheaders()
      }
    }).catch((err)=>{
      setloader(false)
    })
  }
  useEffect(()=>{
    
    getDynamicheaders()
  },[])
  useEffect(() => {
    EventEmitter.addListener('wasession-response', (data) => {
      if (data === "true") {
        setwasession("true")
        setheader((set) => {
          set[0].isrender = "true"
          return set

        })

      } else if (data === "false") {
        setwasession("false")

      }
    })
    if (localStorage.getItem("wasession") === "true") {
      setwasession("true")
    } else {
      setwasession("false")
    }

    let favfilter=localStorage.getItem('filterobj')
    console.log(favfilter,"favfilterfavfilterfavfilter")
    if(favfilter!==null){
     let parsed_data=JSON.parse(favfilter)
     onLeadscreenFavselection (parsed_data,'Filterstart')
    }else{
      fetchLeads()
    }
    if (Filterfield.length == 0) {

      dispatch(CustomformFields(campaigninfo.template_name)).then(
        (res) => {
          
          let filterfield = res.data.data[0].fields.filter((elm) => {
            let excludedModels = [
              'user', 'modified_by', 'created_by', 'deleted', 'api_push',
              'wa_notify', 'sms_notify', 'email_notify', 'modified_date', 
              'created_at','import_id','flag'
            ];
            if(elm.model ==='dialed_count'){
              elm.label ='Dialed Count'
              elm.placeholder ='Dialed Count'
              elm.show_on_popup = true 
            }
            if (
              elm.agent_show_on_filter == true &&
              !excludedModels.includes(elm.model)
            ) {
              if (elm.model === "list_id") {
                elm.fieldType = "SelectList";
              }
              return elm

            } else if (elm.model === "disposition") {
              return elm
            }

          })
          setFilterfield(filterfield)
        },
      ).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'form fields',
        })
      })
    }
    EventEmitter.addListener('auto-dial', (data) => {
      if(data.action === 'reset'){
        getDynamicheaders();
        return
      }
      setdialerdiable(data);
    })

    localStorage.setItem('autodialtimer', dialer_interval)
    setautodialtimer(parseInt(localStorage.getItem('autodialtimer')))
    if (stateValue.call.autodialstatus) {
      setautodialtimerstatus(true)
    } else {
      setautodialtimerstatus(false)
    }

    if (AppPermission.wa_view) {
      dispatch(getTemplate()).then((res) => {
        if (res.data.length > 0) {

          setpopUpData((set) => {
            set.field[0].option = res.data.map(e => {
              e.label = e.name
              e.value = e.id
              return e
            })
            return {
              ...set
            }
          })
        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: "whatsapp Template",
        })
      })
    }
    dispatch(GetColorCode('lead')).then(function (response) {

if(response.status===200){
  setcolorcode(response.data.data[0].condition_logic)
 
}
    })
  }, [])
  function getDynamicheaders(){
    setloader(true)
    const data = {
      user:user_id,
      campaign:selected_campaign,
      module:'lead',
      template:campaigninfo.template_name
    }
    
    dispatch(AsterAgentTableField(data)).then((response)=>{
      if(response.data.statusCode === 200){
        const headerDefault = header[header.length-1];
        const defaultHeader =[headerDefault]
        const newData = response.data.data.map(item => {
          if(item.model === 'phone_number'){
            return{
              masking:true,
              Header: item.label,
              accessor:item.model,
              color:true,
              agent_table_show:item.agent_table_show
            }
          }else{
            return{
              masking:false,
              Header: item.label,
              accessor:item.model,
              agent_table_show:item.agent_table_show
            }
          }
            
          });
        const updatedHeader = [ ...newData,...defaultHeader];
        setheader(updatedHeader) 
        setloader(false)
      }else{
        const headerDefault = header[header.length-1];
        const defaultHeader = [headerDefault];
        setheader(defaultHeader)
        setloader(false)
      }
      // refresh()
      
    }).catch((err)=>{
      EventEmitter.emit('error-message-getter', {
        action: 'field',
        module: "Selected fields are removed Please select field.",
      })
      setloader(false)
    })
  }


  function wapopup(data) {
    setwadata(data)
    setpopUpData((set) => {
      set.open = true
      return {
        ...set
      }
    })
  }
  function closepopup(data) {
    if (data.action === "cancel") {
      setpopUpData((set) => {
        set.open = false
        return {
          ...set
        }
      })
    } else {
      function find_(data_) {

        return data_.value === data.data[0].whatsapp_template;
      }
      let templatedata = {
        "action": "send-wa-template",
        "station": localStorage.getItem('extension'),
        "phone_number": wadata.phone_number.toString(),
        "sender_id": wadata.lead_id.toString(),
        "sender_type": 2,
        "sender_name": wadata.first_name.toString(),
        "tenant_id": JSON.parse(localStorage.getItem("config")).data.tenant_code,
        "agent": localStorage.getItem("user"),
        "template": {
          "id": data.data[0].whatsapp_template,
          "type": "",
          "name": data.field[0].option.find(find_).name,
          "language": data.field[0].option.find(find_).lang
        }
      }
      if (data.action === "create") {
        dispatch(waTemplate(stateValue.background.websocketconnection, templatedata))
        setpopUpData((set) => {
          set.open = false
          return {
            ...set
          }
        })
      }
    }
  }

  const leadView = (row, pageName) => {
    if (pageName === 'leadView') {
      localStorage.setItem('isEdit', false)
      localStorage.setItem('isEditfromLead', true)
      navigate(`/leads/${Encode(row.original.phone_number)}/${row.original.lead_id}`)
    }
  }
  function autodialbtn() {
    setclosefilterpop(true)
    let readystate = localStorage.getItem("selectedPause")
    if (readystate == "available") {
      localStorage.setItem("autodial","true")
      dispatch(autodial(true))
      localStorage.setItem('webrtcManual', 'true')
      dispatch(autocalldial())
      dispatch(callmodule({
        id: stateValue.call.autodialdatas[0].lead_id,
        module: "lead",
        phone_no: stateValue.call.autodialdatas[0].phone_number
      }))
      dispatch(fetchcallaction('on_call'))

    } else {
      EventEmitter.emit('error-message-getter', {
        action: 'ready',
        module: "Agent Not ready",
      })
    }
  }

  function startStopbtnact(data) {
    setplayparsebtn(data)
    setautodialtimerstatus(!autodialtimerstatus)
  }

  function resetautodial() {
    localStorage.setItem("autodial","false")
    dispatch(autodial(false))
    fetchLeads()
    setautodialtimerstatus(false)
    dispatch(autodialdatas([]))
  }

  const fetchLeads = async () => {
    setloader(true)
    const domain = localStorage.getItem('domain')
    dispatch(GetLeadTable(selectedCampaign))
      .then(function (response) {
        setloader(false)
        if (response.data.statusCode === 200) {
          record(false, 'refresh')
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'lead',
        })
      })
  }

  function record(data, search, subfilter_) {
    SetisFilter(false)
    setfiltershow(true)
    let payload
    let teamdata = JSON.parse(localStorage.getItem("Teaminfo"))
    let source = []
    if (Object.keys(teamdata).length > 0) {
      if (teamdata.type == "all" || teamdata.type == "source") {
        source = teamdata.type_data.source.map((elm) => elm.name)
      }
    }
    if (data) {
      payload = payload_data
      payload_data.skip = skiptake.skip
      payload_data.take = skiptake.take
    } else {
      payload = {
        type: `${type}`,
        mainFiltervalue: `${selectedCampaign}`,
        skip: skiptake.skip,
        subFilterValue: subfilter,
        take: skiptake.take,
        user: `${user_id}`,
        source: source
      }
      if (subfilter_ !== undefined) {
        if (subfilter_.length > 0) {
          // payload.subFilterValue = subfilter_
        }
      }

      if (search == "search") {
        setinfinitScroll({ more: true })
        payload.skip = 0
        payload.take = 20
      }
      if (search == "refresh") {
        setinfinitScroll({ more: true })
        payload.skip = 0
        payload.take = 20
      }

    }
    if (search !== "Moredata") {
      setloader(true)
    }
    dispatch(GetLeadSearch(payload)).then(function (response) {
      setloader(false)
      let value = []
      if (response.status === 200) {

        setapidatacount(response.data.count)
        setskiptake((set) => {
          if (payload.take === 20) {
            set.skip = 20
            set.take = 10
          } else {
            set.skip = payload.skip + 10
            set.take = 10
          }
          return { ...set }
        })
        let element = response.data.data
        for (let i = 0; i < element.length; i++) {
          let obj = {};
          for (let key in element[i]) {
            if (element[i].hasOwnProperty(key)) {
              element[i][key] = element[i][key] === null ? "" : element[i][key];
              element[i][key] = element[i][key] === '0000-00-00 00:00:00' ? "" : element[i][key];
              if (typeof element[i][key] !== 'object') {
               obj[key] =reduceLength(Format(element[i][key]));
            }
          }
        }
        obj.all = element[i];
        value.push(obj);
        }
        if (search == "search") {

          setleaddetail([].concat(value))
        }
        else if (payload_data.skip == 0) {
          setleaddetail([].concat(value))
        } else if (search == "refresh") {
          setleaddetail([].concat(value))
        } else {
          setleaddetail(leaddetail.concat(value))
        }

      } else if (response.status == 204) {
        setleaddetail(value)
      }
     

    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'search',
        module: 'lead',
      })
    })
  }

  // filter api

  function searchfilter(e) {
    setsearch(e)
    let payload = { searchvalue: e, subFilterValue: subfilter }
    if (e == '') {
      setskiptake({ skip: 0, take: 10 })
      delete payload.searchvalue
      setpayload({})
      record(false, "search")
    } else {
      refresh('search')
      filterdataapi(payload)
    }
  }

  function filterdataapi(filterdata) {
    let payloaddata = {
      type: `${type}`,
      mainFiltervalue: `${selectedCampaign}`,
      skip: 0,
      take: 20,
      user: `${user_id}`,
    }
    setloader(true)
    setsubfilter(filterdata.subFilterValue)
    let payload = { ...filterdata, ...payloaddata }
    setpayload(payload)
    setinfinitScroll({ more: true })
    dispatch(GetLeadSearch(payload)).then(function (response) {
      setloader(false)
      let value = []
      if (response.status === 200) {
        let element = response.data.data
        setskiptake({ skip: 20, take: 10 })
        for (let i = 0; i < element.length; i++) {
          let obj = {};
          for (let key in element[i]) {
            if (element[i].hasOwnProperty(key)) {
              element[i][key]=element[i][key] === null ? "" :element[i][key];
              element[i][key] = element[i][key] === '0000-00-00 00:00:00' ? "" : element[i][key];
              if (typeof element[i][key] !== 'object') {
               obj[key] = reduceLength(Format(element[i][key]));
              }
          }
        }
        obj.all = element[i];
        value.push(obj);
        }
        setleaddetail(value)
      } else if (response.status == 204) {
        setleaddetail(value)
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'search',
        module: 'lead',
      })
    })
  }


  function refresh(state) {
    setclosefilterpop(true)
    SetisFilter(false)
    setfiltershow(false)
    setsubfilter(["new"])
    setskiptake({ skip: 0, take: 10 })
    record(false, "refresh", ["new"])
    if("search"===state){
      setsearch("")
    }
    
    setpayload({})
    setcurrent_Filter({})
    setFilterCheck('')
    if(state === 'refresh'){
      localStorage.removeItem('filterobj')
    }
    dispatch(getfilter('lead')).then((res) => {
      if (res.status === 200) {
        const f = res.data.data
        const filtereddata = f.filter(
          (item) => item.is_active === "true"
        );
        setList_of_Fav_Filter(filtereddata)
      } else {
        const f = []
        const filtereddata = f.filter(
          (item) => item.is_active === "true"
        );
        setList_of_Fav_Filter(filtereddata)
      }

    })
    // setTimeout(() => {
    // setfiltershow(true)
    // }, 100);
  }


  function clickcall(data) {

    let readystate = localStorage.getItem("selectedPause")
    if (readystate == "available") {
      let payload = {
        state: stateValue.background.websocketconnection,
        value: stateValue.call.autodialstatus == false || stateValue.call.autodialstatus == true ? data.original.phone_number.toString() : data.original.phone_number.toString()
      }

      dispatch(callmodule({
        id: stateValue.call.autodialstatus == false || stateValue.call.autodialstatus == true ? data.original.lead_id : data.original.lead_id,
        module: "lead",
        phone_no: stateValue.call.autodialstatus == false || stateValue.call.autodialstatus == true ? data.original.phone_number.toString() : data.original.phone_number.toString()
      }))
      dispatch(Outbound(payload.state, payload.value))
    } else {
      EventEmitter.emit('error-message-getter', {
        action: 'ready',
        module: "Agent Not ready",
      })
    }

  }


  let fetchMoreData = () => {

    if(!isFilter){
      if (leaddetail.length >= apidatacount) {
        setinfinitScroll((set) => {
          set.more = false
          return {
            ...set,
          }
        })
      } else {
        setTimeout(() => {
          if (Object.keys(payload_data).length > 0) {
            record(true, 'Moredata')
          } else {
            record(false, 'Moredata')
          }
  
        }, 1000)
      }
    }else{
      if (leaddetail.length >= apidatacount) {

        setinfinitScroll((set) => {
          set.more = false
          return {
            ...set,
          }
        })
      } else{
        SetisFilter(true)
        setskiptake((set)=>{
          set.take = 20
          set.skip = set.skip + 20
          return set
        })
        onLeadscreenFavselection(FilterCondition,"Moredata")
      }
    }

  }
  const createLead = () => {
    navigate(`/leads/create`)
  }
  const sendlist_of_Filter = (data) => {
    setList_of_Fav_Filter(data)
  }
  const dataAfterfilter = (data, id,filterdata) => {
    try {
    const value = []
    if (data != undefined) {
      for (let i = 0; i < data.length; i++) {
        let obj = {};
        for (let key in data[i]) {
          if (data[i].hasOwnProperty(key)) {
            data[i][key]=data[i][key] === null ? "" :data[i][key];
            data[i][key] = data[i][key] === '0000-00-00 00:00:00' ? "" : data[i][key];
            if (typeof data[i][key] !== 'object') {
            obj[key] = reduceLength(Format(data[i][key]));  
          }
        }
      }
      obj.all = data[i];
      value.push(obj);
      }
    }
    if(typeof id ==='number'){
      
      if(filterdata !==undefined){
        let val= filterdata.filter((item)=>{
          return item.id === id
        })
        
        val[0].display_count=value.length
        setcurrent_Filter(val[0])
        setleaddetail(value)
      }else{
        let val= List_of_Fav_Filter.filter((item)=>{
          return item.id === id
        })
        val[0].display_count=value.length
        setcurrent_Filter(val[0])
        setleaddetail(value)
      }
      
    
    }
    if(typeof id =='object'){
      if(filterdata == "Moredata"){
        setleaddetail(leaddetail.concat(value))
      }else{
        setleaddetail(value)
      }
      setcurrent_Filter(id)
    }
    localStorage.setItem("filterobj",JSON.stringify(id))
    
   
  } catch (error) {
    console.log(error,'checking filter error')

  }  
  }
  
  function isValidDateString(str) {
    return !isNaN(Date.parse(str));
  }
  const reduceLength = (value) =>{
    if (typeof value === 'string' && isValidDateString(value) && value.length > 11) {
      let datetime = new Date(value)
      let dd = datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate()
      let mm = datetime.getMonth() + 1 < 10 ? `0${datetime.getMonth() + 1}` : datetime.getMonth() + 1
      let hr = datetime.getHours() < 10 ? `0${datetime.getHours()}` : datetime.getHours()
      let min = datetime.getMinutes() < 10 ? `0${datetime.getMinutes()}` : datetime.getMinutes()
      value = datetime.getFullYear() + `-${mm}-${dd} ${hr}:${min}`
    }
    else if(typeof value=== "string" && value.length >= 17 ){
      value = value.substring(0, 17)+'...';
      return value
    }
    return value
  }
  const onLeadscreenFavselection = (item, pageCond) => {
    if(pageCond == "Filterstart"){
      SetisFilter(true)
      skiptake.skip = 0
      skiptake.take = 20
      setloader(true)
      setinfinitScroll((set) => {
        set.more = true
        return {
          ...set,
        }
      })
    }
    SetFilterCondition(item)
    let teamdata = JSON.parse(localStorage.getItem("Teaminfo"))
    let source = []
    if (Object.keys(teamdata).length > 0) {
      if (teamdata.type == "all" || teamdata.type == "source") {
        source = teamdata.type_data.source.map((elm) => elm.name)
      }
    }
    const payload = {
      user_name: localStorage.getItem('user'),
      source: source,
      campaign_name: selectedCampaign,
      filter_data: item.filter_data ? item.filter_data : {},
      module:'lead',
      skip: skiptake.skip,
      take: skiptake.take
    }
    // setloader(true)
    dispatch(getfilterdata(payload)).then((res => {
      setloader(false)
      if (res.status === 200) {
        dataAfterfilter(res.data.data, item, pageCond)
        setapidatacount(res.data.count)
        item.display_count = res.data.count
        localStorage.setItem("filterobj",JSON.stringify(item))
        setcurrent_Filter(item)

      }
      else {
        item.display_count = 0;
        dataAfterfilter(res.data.data, item)
        setcurrent_Filter(item)
      }
      setFilterCheck('check')
    }))
  }
  const clearallfilter = () => {
    const f = {}
    setcurrent_Filter({})
    onLeadscreenFavselection({})
  }
  const closefilterpopresponse=()=>{
    setclosefilterpop(false)
  }
  const structureChange = () =>{
    const changeHead = campaigninfo.call_type == "PREVIEW" ? 3 : 4
    if(header.length <= changeHead || loader){
      return ''
    }
    return 'structureChange'
  }
  return (

    <div className="page-main-container page-main-container-mobile">
      <DynamicField
      module={'lead'}  
      dynamicHead={dynamicHead}
      saveDynamicPopup={saveDynamicPopup}
      />
      <Dialogbox
        fields={closepopup}
        value={popUpData}
        className="dialog-body-default1"
        // cancel={{ display: 'none' }}
        save={'disabled'}
      />
      <div className="scroll-lft" md={8}>
        <div className="lead-header">
          <div className="lead-header1">
            <div className='route-header lead-header1-lft'> Leads</div>
            {AppPermission.lead_search && <div className="lead-search">
              <span className="material-icons lead-search-icon">search</span>
              <Form.Control
                onChange={(e) => {
                  searchfilter(e.target.valautodialdatasue)
                }}
                value={search}
                type="text"
                placeholder="Search"
                style={{
                  padding: 10,
                  'padding-left': '40px',
                  border: "1px solid #e9ecef",
                  backgroundColor: '#fff',
                }}
              />
            </div>}
          </div>
         <div className="lead-header2">
          
            {AppPermission.lead_refresh &&
              <OverlayTrigger
                placement={'bottom'}
                overlay={<Tooltip>Refresh</Tooltip>}
              >
                <div
                  onClick={() => { refresh('refresh') }}
                  className="lead-save-icons lead-create-head-rht material-icons"
                >
                  refresh
                </div></OverlayTrigger>}
              <OverlayTrigger
                placement={'bottom'}
                overlay={<Tooltip>Header settings</Tooltip>}>
                  <div
                      className="settings material-icons lead-table-button"
                      style={{ color: '#3e98c7' }}  
                      onClick={() => handleSettingsClick(dynamicHead)}
                    >
                    settings
                  </div>
              </OverlayTrigger>
              <div >
                <Customfilter
                closefilterpop={closefilterpop}
                closefilterpopresponse={closefilterpopresponse}
                  fields={Filterfield}
                  dataAfterfilter={dataAfterfilter}
                  module={'lead'}
                  filterCheck={filterCheck}
                  setFilterCheck={setFilterCheck}
                  onLeadscreenFavselection={onLeadscreenFavselection}
                  sendlist_of_Filter={sendlist_of_Filter}
                  List_of_Fav_Filter={List_of_Fav_Filter}
                />
              </div>
            <div>
              {stateValue.call.autodialstatus == false && AppPermission.auto_dial && campaigninfo.call_type == "PREVIEW" && (
                <span
                  disabled={dialerdiable === 'false'}
                  onClick={() => {
                    autodialbtn()
                  }}
                  className={
                    stateValue.call.autodialdatas.length === 0
                      ? 'material-icons lead-table-button disabledbutton' : wasession === "true" ? 'material-icons lead-table-button disabledbutton'
                        : 'material-icons lead-table-button lead-dial-button-green'
                  }
                >
                  settings_phone
                </span>
              )}
            </div>
            {AppPermission.lead_create  &&adminPermission('leadmanagement:lead:create')&&
              <OverlayTrigger
                placement={'bottom'}
                overlay={<Tooltip>Add</Tooltip>}
              >
                <div
                  onClick={createLead}
                  className="material-icons lead-table-button "
                >
                  add_circle
                </div></OverlayTrigger>}
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px', width: '100%',overflowX: 'auto'  }} >


          {
            List_of_Fav_Filter.map((item, i) => {
              if (item.is_favourite === "true") {
                return (
                  <button
                    onClick={() => {
                      onLeadscreenFavselection(item,"Filterstart")

                    }}
                    style={{
                      margin:'10px',
                      paddingLeft: '10px', borderRadius: '5px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px', marginRight: '10px',
                      background: current_Filter.id === item.id ? '#2196f3' : '#e9e9e9',
                      border: 0,
                      color: current_Filter.id === item.id ? 'white' : '#b2a9a9'
                    }}
                  >
                  {item.filter_name}  {item.id===current_Filter.id ? current_Filter.display_count : item.display_count}
                  </button>
                )
              }

            })
          }

          <div>
            {/* <button
 style={{
 paddingLeft: '10px', borderRadius: '77px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px', marginRight: '10px',
 background: 'red',
 border: 0,
 color: 'white'
 }}

 onClick={() => clearallfilter()}>
 Clear Filter
 </button> */}
          </div>

        </div>
        {colorcode.length>0&&<ColorBar ColorCode={colorcode}></ColorBar>}
        <div className="lead-body">
          <div
          className={structureChange()}          
            id="ticketDiv"
            style={{
              height: '100%',
              overflow: 'auto',
              color: 'grey',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <InfiniteScroll
              dataLength={leaddetail.length}
              next={fetchMoreData}
              hasMore={infinitScroll.more}
              scrollableTarget="ticketDiv"
            >
              {<LeadTable
                loader={loader}
                checkbox={campaigninfo.call_type == "PREVIEW"}
                header={header}
                leaddetail={leaddetail}
                type="lead"
              />}
            </InfiniteScroll>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Leads
