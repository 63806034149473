import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Timeline from './Lead-timeline'
import CustomForm from './Lead-CustomForm'
import Meeting from './Lead-meeting'
import EventEmitter from '../../utils/EventEmitter'
import OtherTickets from './Other-Ticket'
import Notepad from './Lead-notepad'
import Script from './Lead-script'
import { useNavigate } from 'react-router'
import { useParams, useLocation } from 'react-router-dom'
import {
  ContactFetch,
} from '../../redux/actions/ContactActions'
import { PopupRouter } from '../../redux/actions/Customform'
import { CustommoduleFetch } from '../../redux/actions/Customform'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import whatsapp from '../../assets/images/whatsapp.svg'
import { Encode, Decode } from '../../utils/EncodeDecode'
import LinkLead from '../Lead/Lead-template'
import { adminPermission } from '../../utils/localstorage-provider'
import { useOutletContext } from 'react-router-dom';
function LeadMainView(props) {
  const { triggerCallScreenFunction ,outletData} = useOutletContext();
  let dispatch = useDispatch()
  let AppPermission
  let navigate = useNavigate()
  const agent = localStorage.getItem('user')
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  const [custom_module, setcustom_module] = useState([])
  const [count, setCount] = useState(0)
  const childRef = useRef(null)
  const [id, setid] = useState('')
  const [name, setname] = useState('noname')
  const [save, setsave] = useState(false)
  let { phone_number, meeting_id } = useParams()
  phone_number = Decode(phone_number)
  const location = useLocation()
  const [type, settype] = useState("")
  const [datastatus, setdatastatus] = useState(true)
  let [fetchapi, setfetchapi] = useState(false)
  let [regexvalue, setregexvalue] = useState(true)
  let [triggerdispo, settriggerdispo] = useState(false)


  useEffect(() => {
    
    if(outletData){ 
      
     setCount(1)
     settriggerdispo(true)
    
     
    }
   }, [outletData])

  useEffect(() => {
    dispatch(CustommoduleFetch({ template_name: JSON.parse(localStorage.getItem("selectedCampaign_data")).template_name, module: "lead" })).then((response) => {
     
      if (response.data.statusCode === 200) {
        setcustom_module(response.data.data)
      } else {
        setcustom_module([])
      }
    })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'create',
          module: 'activity',
        })
      })
    if (location.pathname === `/meeting/${Encode(phone_number)}/${meeting_id}` && AppPermission.view_meeting) {
      if (AppPermission.meeting_detail) {
        setCount(3)
      } else if (AppPermission.meeting_custom_form) {
        setCount(1)
      }
      settype("meeting")
    }
    else if (location.pathname === `/voicemail/${Encode(phone_number)}` && AppPermission.voicemail_view) {
      settype("voicemail")
      if (AppPermission.voicemail_custom_form) {
        setCount(1)
      } else if (AppPermission.view_timeline_ui) {
        setCount(2)
      } else if (AppPermission.lead_history) {
        setCount(7)
      } else if (AppPermission.lead_ticket) {
        setCount(4)
      } else if (AppPermission.create_note) {
        setCount(5)
      } else if (AppPermission.lead_meeting_log) {
        setCount(3)
      }
    }
    else {
      setCount(1)
      settype('lead')
    }
  }, [])


  useEffect(() => {
    if (count === 1) {
      localStorage.setItem("oncustomform", "true")
    } else {
      localStorage.removeItem("oncustomform")
    }
  }, [count])
  useMemo(() => {
    if (props.formdatas.status !== undefined) {
      if (props.formdatas.status === 200) {
        setname(props?.formdatas?.data?.data[0]?.others?.first_name)
        setid(props?.formdatas?.data?.data[0]?.others?.lead_id)
      } else if (props.formdatas.status === 204) {
        setdatastatus(false)
      }
    }
  }, [props.formdatas])


  useEffect(()=>{
    if(custom_module.length > 0){
      if (props.formdatas.status !== undefined) {
        if (props.formdatas.status === 200) {


          setcustom_module((set)=>{
            set.forEach(element=>{
              let ele = element
              let arr=[]
              if(ele.urlparams.length>0 && Array.isArray(ele.urlparams)){
                ele.urlparams.forEach((elm=>{
                  if(props.formdatas.data.data[0].others.hasOwnProperty(elm)){
                    arr.push({name: elm ,value:props.formdatas.data.data[0].others[elm]})
                  }else{
                    arr.push({name: elm ,value:""})
                  }
                }))
                ele.urlparams =  arr
                
              }
            })


            return set
          })


          console.log("sanlog",custom_module)
          // if(custom_module.fetch_type == "internal"){

          // }
          // props.formdatas.data.data[0].others
        } else if (props.formdatas.status === 204) {
          // props.formdatas.data.data[0].others
        }
      }
    }
  },[custom_module])

  function contactdialogopen(phone,model) {
    if(model==="tickets"){
      let payload = {
        phone_number: phone
      }
      payload.module = 'tickets'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].ticket_id
          navigate(`/tickets/${Encode(phone)}/${id}`)
        } else {

          navigate('/tickets/create')
          localStorage.setItem(`setStatic_number`,`${phone}`)
        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    }else{
      let payload = {
        phone_number: phone
      }
      payload.module = 'contacts'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].id
          navigate(`/contact/${Encode(phone)}/${id}`)
        } else {

          navigate('/contact/create')
          localStorage.setItem(`setStatic_number`,`${phone}`)
          localStorage.setItem(`Parant_data`,JSON.stringify(props.formdatas.data.data))
        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    }
    
  }

  function update() {
    setsave(true)
  }


  function saveleaddetail() {
    setsave(false)
  }


  function fetchdata(ticket) {

    props.fetchapi(!fetchapi)


  }
  function closedispo(ticket) {

   settriggerdispo(false)
   triggerCallScreenFunction(false)

  }
  function regexval(data) {
    if (data) {
      setregexvalue(true)
    } else {
      setregexvalue(false)
    }
  }
  function WaRedirect(number) {
    //window.location.href = 'https://wa.me/91'+number;
    let url = "https://wa.me/91"
    if (number.match(/^\d{10}$/)) {
      url = 'https://wa.me/91' + number
    } else {
      url = 'https://wa.me/' + number
    }
    window.open(url, '_blank')
  }
  function navigatecustom(location, item) {
    localStorage.setItem("module_data", JSON.stringify(item))
    localStorage.setItem("module_back", location.pathname)
    navigate(`/custom-module/${item.template_name}`)
  }
  return (
    <>
      {props.formdatas.status === 200 && <div className="activity" style={{ cursor: 'pointer' }}>
        <div>
          {location.pathname === `/meeting/${Encode(phone_number)}/${meeting_id}` && (type == "meeting" && AppPermission.view_meeting && AppPermission.meeting_detail) && (
            <span
              onClick={() => setCount(3)}
              className={count === 3 ? 'active' : ''}
            >
              Meetings
            </span>
          )}
          {(type == "lead" && AppPermission.lead_custom_form || type === "meeting" && AppPermission.meeting_custom_form || type == "voicemail" && AppPermission.voicemail_custom_form) &&
            <span
              onClick={() => setCount(1)}
              className={count === 1 ? 'active' : ''}
            >
              Details
            </span>}
            {AppPermission.lead_history && props.operation !== true && <span
            onClick={() => setCount(8)}
            className={count === 8 ? 'active' : ''}
          >
            {' '}
            All Leads
          </span>}
          {AppPermission.view_timeline_ui && props.operation !== true && <span
            onClick={() => setCount(2)}
            className={count === 2 ? 'active' : ''}
          >
            {' '}
            Activity
          </span>}
          {AppPermission.lead_history && props.operation !== true && <span
            onClick={() => setCount(7)}
            className={count === 7 ? 'active' : ''}
          >
            {' '}
            History
          </span>}

          {(type == "meeting" && AppPermission.meeting_ticket || type == "lead" && AppPermission.lead_ticket || type == "voicemail" && AppPermission.voicemail_ticket) && props.operation !== true && <span
            onClick={() => setCount(4)}
            className={count === 4 ? 'active' : ''}
          >
            Tickets
          </span>}
          {location.pathname !== `/meeting/${Encode(phone_number)}/${meeting_id}` && (type == "lead" && AppPermission.lead_meeting_log || type == "voicemail" && AppPermission.voicemail_meeting_log) &&
            (
              <span
                onClick={() => setCount(3)}
                className={count === 3 ? 'active' : ''}
              >
                Meetings
              </span>
            )}

          {AppPermission.create_note &&
            <span
              onClick={() => setCount(5)}
              className={count === 5 ? 'active' : ''}
            >
              Comments
            </span>}



        </div>
      </div>}
      <div
        className={
          count === 3
            ? 'lead-main-card-meeting'
            : count === 1
              ? 'oh lead-main-card'
              : 'lead-main-card'
        }
      >
        {count === 1 && (type == "lead" && AppPermission.lead_custom_form || type === "meeting" && AppPermission.meeting_custom_form || type == "voicemail" && AppPermission.voicemail_custom_form) && (
          <>
            <div className="Customform-catagory Customform-header">
              <div className="Customform-catagory-lft">

                <div className="Customform-category-title">
                  #{id || ''} Lead Information
                </div>
              </div>
              <div className="Customform-catagory-rht">
                <div className="Customform-save-bts">
                  {custom_module.length > 0 && location.pathname !== "/leads/create" && <>
                    {custom_module.map((item, i) => {
                      return (
                        <>
                        <OverlayTrigger
                    placement={'bottom'}
                    overlay={<Tooltip>{item.template_name}</Tooltip>}
                    key={i}
                  >
                          {AppPermission.view_custommodule && <div onClick={() => { 
                            navigatecustom(location,item)
                           }} key={i} className="navbar-custom-module-icon navbar-logo-img Customform-save-icon wa-icon">{item.template_name.split("")[0].toUpperCase()}</div>}
                        </OverlayTrigger>
                        </>

                      )
                    })
                    }
                  </>}

                  {AppPermission.wa_nativewhatsapp && location.pathname !== "/leads/create" && <OverlayTrigger 
                    placement={'bottom'}
                    overlay={<Tooltip>Native whatsapp</Tooltip>}
                  >
                  <img
                    className="navbar-logo-img Customform-save-icon wa-icon"
                    src={whatsapp}
                    alt="Text0"
                    onClick={() => { WaRedirect(phone_number) }}
                   
                  />
                   </OverlayTrigger>}
                  {adminPermission('leadmanagement:contacts:create')&&props.operation == false && <OverlayTrigger
                    placement={'bottom'}
                    overlay={<Tooltip>Contact</Tooltip>}
                  >
                    {AppPermission.contact_create && AppPermission.view_contacts && <div
                      className="material-icons Customform-save-icon "
                      onClick={() => { contactdialogopen(phone_number,'contacts') }}
                    >
                      contacts
                    </div>}
                   
                  </OverlayTrigger>}
                  {adminPermission('leadmanagement:tickets:create')&&adminPermission('leadmanagement:tickets:view')&&props.operation == false && AppPermission.ticket_create && AppPermission.ticket_view && <OverlayTrigger
                    placement={'bottom'}
                    overlay={<Tooltip>Ticket</Tooltip>}
                  >
                   
                    <div
                      className="material-icons Customform-save-icon "
                      onClick={() => { contactdialogopen(phone_number,'tickets') }}
                    >
                      confirmation_number
                    </div>
                  </OverlayTrigger>}
                  {adminPermission('leadmanagement:lead:edit')&& <OverlayTrigger
                    placement={'bottom'}
                    overlay={<Tooltip>save</Tooltip>}
                  ><div
                    onClick={update}
                    className={regexvalue ? "material-icons Customform-save-icon " : "material-icons Customform-save-icon disabledbutton"}
                  >
                    save
                  </div></OverlayTrigger>}
                </div>
              </div>
            </div>
            <div
              className="lead-main-card_sub-content"
            >
              <CustomForm
                fetchapi={fetchdata}
                triggerdispo={triggerdispo}
                closedispo={closedispo}
                savebutton={save}
                saveleaddetail={saveleaddetail}
                operation={props.operation}
                formdatas={props.formdatas}
                statedata={props.statedata}
                regexval={regexval}
              />
            </div>
          </>
        )}
        {count === 2 && AppPermission.view_timeline_ui && props.operation !== true && (
          <>
            <Timeline view={"action"} type={"lead"} id={id} name={name} />
          </>
        )}
        {count === 7 && AppPermission.lead_history && props.operation !== true && (
          <>
            <Timeline view={"history"} type={"lead"} id={id} name={name} />
          </>
        )}
        {count === 3 && (type == "meeting" && AppPermission.view_meeting && AppPermission.meeting_detail || type == "lead" && AppPermission.lead_meeting_log || type == "voicemail" && AppPermission.voicemail_meeting_log) &&
          (
            <>
              <Meeting type={type} id={id} name={name} />
            </>
          )}

        {count === 4 && (type == "meeting" && AppPermission.meeting_ticket || type == "lead" && AppPermission.lead_ticket || type == "voicemail" && AppPermission.voicemail_ticket) && props.operation !== true &&
          (
            <>
              <OtherTickets type={type} />
            </>
          )}
        {count === 5 && AppPermission.create_note && (
          <>
            <Notepad
              basicdata={{
                id: id,
                phone_number: phone_number,
                module: "lead"
              }}
            />
          </>
        )}
        {count === 8 && AppPermission.contact_lead_template &&
          (
            <LinkLead type={"contact"} />

          )}
      </div>
    </>
  )
}

export default LeadMainView