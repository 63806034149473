import React, {
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useMemo,
} from 'react'
import Timer from '../Timepicker'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import EventEmitter from '../../utils/EventEmitter'
import { callLeadDetail, triggerdispostate, triggerreqpopup } from "../../redux/background/BackgroundAction";
import '../../assets/css/Customform.css'
import Form from 'react-bootstrap/Form'
import TimePicker from 'react-times'
import moment from 'moment'
import 'react-times/css/material/default.css'
import 'react-times/css/classic/default.css'
import { BsFillHexagonFill } from 'react-icons/bs'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import ChatBox from "../chatBox.js";
import { CiChat2 } from "react-icons/ci";
import {

  CustomFormCreate, GetChannel
} from "../../redux/actions/Customform";
import Select from 'react-select'
import {
  TicketTagManagement,
  TicketCreate,
  TicketUpdate,
  TicketGetRequestModule,
  TicketStatus,
} from '../../redux/actions/TicketAction.js'
import {
  contactCreate
} from '../../redux/actions/ContactActions'
import { useParams, useLocation } from "react-router-dom";
import Dialogbox from '../Dialogbox'
import Regex_ from '../../utils/regex'
import Format from '../../utils/format-text'
import { Encode, Decode } from '../../utils/EncodeDecode'
import { useOutletContext } from 'react-router-dom';
import fromat from '../../utils/format-text'
function CustomForm(props, ref) {
  const { triggerCallScreenFunction ,outletData} = useOutletContext();
  const location = useLocation()
  const [regexvalidation, setregexvalidation] = useState({});
  const [textcount, settextcount] = useState({})
  let { phone_number } = useParams()
  phone_number = Decode(phone_number)
  const [channel, setchannel] = useState([])
  let [status, setstatus] = useState([])
  let [datetimemodel, setdatetimemodel] = useState([])
  let state_value = useSelector((state) => state)
  let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
  let Dispatch = useDispatch()
  let navigate = useNavigate()
  let [chatdata, setchatdata] = useState({})
  const [customField, setcustomField] = useState([])
  const [formdata, setformdata] = useState({})
  const [cloneformdata, setcloneformdata] = useState({})
  const [catagory, setcatagory] = useState([])
  const [catagoryvalue, setcatagoryvalue] = useState('')
  const [updatedata, setupdatedata] = useState({})
  const [templetedata, settempletedata] = useState({})
  const [validate, setvalidate] = useState(false)
  const [openDialog, setopenDialog] = useState(false)
  const [ticketid, setticketid] = useState(0)
  const [leadcreatestatus, setleadcreatestatus] = useState(false)
  const [Listdata, setlistdata] = useState(
    JSON.parse(localStorage.getItem('list')).map((elm) => {
      return { label: elm.list_id, value: elm.list_id.toString() }
    }),
  )
  const [tags, settags] = useState([])
  const [popupdata, setpopupdata] = useState({
    open: false,
    Type: 'Notification_bar',
    field: [],
    title: 'update ticket ',
    Message: 'ticket updated successfully!!! ',
  })
  const [flagnumber, setflagnumber] = useState(false)

  const [requiredpopupdata, setrequiredpopupdata] = useState({
    open: false,
    Type: 'Notification_bar',
    field: [],
    title: 'Require field error',
    Message: 'please fill require field!!! ',
  })
  const [mappingfield,setmappingfield] = useState([])
  const [CreateContactProp, setCreateContactProp] = useState({})
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  useMemo(() => {
    mounted()
    gettag()
  }, [phone_number,props.formdatas])

  // useImperativeHandle(ref, () => ({
  //   updateapi() {
  //     update()
  //   },
  // }))
  useEffect(() => {
    
    if(props.triggerdispo){ 
     update(true)
     props.closedispo()
    }
   }, [props.triggerdispo])
  useEffect(() => {
    if (props.savebutton) {
      update()
    }
  }, [props.savebutton])
  useEffect(()=>{
    localStorage.setItem("formvalidate",validate)
  },[validate])
  useMemo(() => {
    let emptyobj = { fields: [] }
    for (const key in formdata) {
      if (key == 'modified_date' || key == 'modified_at') {
        formdata[key] = currenttime()
      }
      if (key === 'ticket_id') {
        setticketid(formdata[key])
      }
      if (key === 'modified_by') {
        emptyobj.fields.push({
          name: key,
          value: localStorage.getItem('user'),
        })
      }
      if (leadcreatestatus && key === 'created_by') {
        emptyobj.fields.push({
          name: key,
          value: localStorage.getItem('user'),
        })
      }

      if (
        key !== 'lead_id' &&
        key !== 'ticket_id' &&
        key !== 'created_at' &&
        key !== 'phone_number' &&
        key !== 'list_id' &&
        key !== 'created_by' &&
        key !== 'hopper_status' &&
        key !== 'modified_by' &&
        key !== 'created_at'
      ) {
        let datedate = new Date(formdata[key])
        if (Object.prototype.toString.call(datedate) === "[object Date]" && key !== "modified_date") {
          if (!isNaN(datedate.getTime()) && datetimemodel.includes(key)) {
            const yyyy = datedate.getFullYear();
            let mm = datedate.getMonth() + 1;
            let dd = datedate.getDate();
            let hh = datedate.getHours();
            let MM = datedate.getMinutes();
            let ss = datedate.getSeconds();
            if (dd < 10) dd = "0" + dd;
            if (mm < 10) mm = "0" + mm;
            if (hh < 10) hh = "0" + hh;
            if (MM < 10) MM = "0" + MM;
            if (ss < 10) ss = "0" + ss;

            const formattedToday =
              yyyy + "-" + mm + "-" + dd + " " + hh + ":" + MM + ":" + ss;
            emptyobj.fields.push({
              name: key,
              value: formattedToday,
            });
          } else {
            emptyobj.fields.push({
              name: key,
              value: formdata[key],
            });
          }
        }
      }

      if (leadcreatestatus === true) {
        if (key === 'phone_number' || key === 'list_id') {
          emptyobj.fields.push({
            name: key,
            value: formdata[key],
          })
        }
      }
    }
    setupdatedata(emptyobj)
  }, [formdata])
  useEffect(() => {
    if (state_value.background.triggerdispo && !AppPermission.auto_dispo && state_value.background.triggerreqpopup) {
      update();
    } else {
      Dispatch(triggerdispostate(false));
    }
  }, [state_value.background.triggerdispo]);
  // useEffect(() => {

  //   if (state_value.background.triggerreqpopup) {
  //     update()
  //     Dispatch(triggerreqpopup(false));
  //   }
  // }, [state_value.background.triggerreqpopup]);
  useEffect(() => {
    for (let i = 0; i < customField.length; i++) {
      let elm = customField[i]
      if (
        formdata[`${elm.model}`] !== '' &&
        formdata[`${elm.model}`] !== null &&
        formdata[`${elm.model}`] != undefined
      ) {
        if (elm.fieldType == 'Checkbox') {
          handleChangedata(elm, {
            checked: formdata[`${elm.model}`],
            value: elm.model,
          })
        } else {
          handleChangedata(elm, { value: formdata[`${elm.model}`] })
        }
      }
    }
  }, [cloneformdata])


  useEffect(()=>{
    if(props.operation  == true){      
      if(state_value.background.tempdata.type  == "contact"){
        let tempvalue = state_value.background.tempdata.data
        mappingfield.forEach((elm)=>{
          if(state_value.background.tempdata.type == elm.mapping.module){
            setformdata((newlead)=>{
              newlead[elm.name] = tempvalue[elm.mapping.value]
              return newlead
            })
          }
        })


      }else {

        mappingfield.forEach((elm)=>{
          if(state_value.background.tempdata.type == elm.mapping.module){
            setformdata((newlead)=>{
              newlead[elm.name] = ""
              return newlead
            })
          }
        })
      }
    }
  },[state_value.background.tempdata.type,state_value.background.tempdata.data])

  function currenttime() {
    const today = new Date()
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1
    let dd = today.getDate()
    let hh = today.getHours()
    let MM = today.getMinutes()
    let ss = today.getSeconds()
    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    if (hh < 10) hh = '0' + hh
    if (MM < 10) MM = '0' + MM
    if (ss < 10) ss = '0' + ss

    const formattedToday =
      yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + MM + ':' + ss
    return formattedToday
  }
  function mounted() {
    let newlead = {}
    setcustomField(props.statedata.fields)
    let payload = {
      template_name: campaigninfo.template_name,
      type: campaigninfo.type,
      phone_number: phone_number,
    }
    let modeldata = []
    let validateobj = {}
    let txtcount = {}
    props.statedata.fields.forEach((element) => {
      if(element?.custom_property?.is_map == "true"){
          setmappingfield((set)=>{
            set.push({
              name:element.model,
              mapping:element.custom_property.map_property
            })
            return set
          })
      }

      if(element?.custom_property?.is_contact_create == "true"){
        setCreateContactProp((set)=>{
          set[element.model] = element?.custom_property?.contact_create_prop
          return set
        })
      }
      validateobj[`${element.model}`] = false
      txtcount[`${element.model}`] = 255
      if (element.model === "phone_number" || element.model === "user") {
        if (location.pathname === '/tickets/create') {
          phone_number = state_value.background.socket_message.phonenumber
          if (state_value.background.socket_message.phonenumber !== "" && state_value.background.socket_message.phonenumber !== null && state_value.background.socket_message.phonenumber !== undefined) {
            setflagnumber(true)
            Dispatch(TicketGetRequestModule(state_value.background.socket_message.phonenumber, campaigninfo.template_name))

          }
        }
        if (localStorage.getItem(`setStatic_number`) !== null) {
          Dispatch(TicketGetRequestModule(localStorage.getItem(`setStatic_number`), campaigninfo.template_name))
          phone_number = localStorage.getItem(`setStatic_number`)
        }
        newlead["phone_number"] = phone_number;
        newlead["user"] = localStorage.getItem("user");
      } else {
        newlead[element.model] = ''
        if (element.hasDefault && element.show_on_popup) {
          if (element.default != null && element.default != "" ) {
            newlead[element.model] = element.default
          }
        }
      }

      if (element.fieldType == "DatetimePicker") {
        modeldata.push(element.model)
      }
    })
    settextcount(txtcount)
    setregexvalidation(validateobj)
    setdatetimemodel(modeldata)
    let res = props.formdatas
    if (res.status == 204) {
      setleadcreatestatus(true)
      setpopupdata((set) => {
        set.title = 'create ticket'
        set.Message = 'ticket created successfully!!! '
        return {
          ...set,
        }
      })
      setformdata(newlead)
      settempletedata(payload)
    } else if (res.data.statusCode == 200) {
      payload.id = res.data.data[0].others.ticket_id
      settempletedata(payload)
      setleadcreatestatus(false)
      setformdata(res.data.data[0].others)
      res.data.data[0].basic.id = res.data.data[0].others.lead_id
    }
    let customField_data = props.statedata.fields
    let catagory_array = []
    let child_fielddata = customField_data.filter(
      (elm) => elm.link_type == 'Child',
    )
    let parent_fielddata = customField_data.filter(
      (elm) => elm.link_type == 'Parent',
    )

    let obj = {}
    for (let i = 0; i < customField_data.length; i++) {
      const element = customField_data[i].catagory_value

      if (
        customField_data[i].model === 'lead_id' &&
        catagory_array.includes(element) !== true
      ) {
        catagory_array.splice(0, 0, element)
      } else if (!catagory_array.includes(element)) {
        catagory_array.push(element)
      }

      if (
        (customField_data[i].link_type === 'Child' &&
          customField_data[i].is_link) ||
        customField_data[i].disabled ||
        customField_data[i].model === 'modified_date' ||
        customField_data[i].model === 'lead_id' ||
        customField_data[i].model === 'phone_number' ||
        customField_data[i].model === 'user' ||
        customField_data[i].model === 'created_by' ||
        customField_data[i].model === 'created_at' ||
        customField_data[i].model === 'modified_at' ||
        customField_data[i].model === 'modified_by' ||
        customField_data[i].model === 'req_id' ||
        customField_data[i].model === 'req_module' ||
        customField_data[i].model === 'user'
      ) {
        if(customField_data[i].model === "phone_number" ){
          customField_data[i]["visible"] = true;
          customField_data[i]["disabled"] = true; //last work
        }else{
          customField_data[i]["visible"] = false; //last work
        }

        if (res.status == 204) {
          if (
            customField_data[i].model === 'list_id' ||
            customField_data[i].model === 'phone_number' ||
            customField_data[i].model === 'email'
          ) {
            customField_data[i]['visible'] = true
            customField_data[i].disabled = false
            customField_data[i].readonly = false
          }

          if (
            customField_data[i].model === 'user' ||
            customField_data[i].model === 'list_id'
          ) {
            if (customField_data[i].fieldType == 'TextInput') {
              customField_data[i].fieldType = 'SelectList'
            }
          }
        } else {
          if (customField_data[i].model === 'req_module') {
            customField_data[i]['visible'] = true
            customField_data[i].disabled = false
            customField_data[i].readonly = true
          }
        }
      } else if (
        customField_data[i].link_type === 'false' ||
        customField_data[i].link_type === 'Parent'
      ) {
        customField_data[i]['visible'] = true
      }
    }

    parent_fielddata.forEach((elm) => {
      child_fielddata.forEach((elem) => {
        if (elem.linked_to == elm.model) {
          if (
            (elm.fieldType == 'RadioButton' &&
              elem.fieldType == 'SelectList') ||
            (elm.fieldType == 'RadioButton' &&
              elem.fieldType == 'RadioButton') ||
            (elm.fieldType == 'RadioButton' &&
              elem.fieldType == 'SelectList') ||
            (elm.fieldType == 'Checkbox' && elem.fieldType == 'RadioButton') ||
            (elm.fieldType == 'Checkbox' && elem.fieldType == 'SelectList') ||
            (elm.fieldType == 'SelectList' && elem.fieldType == 'SelectList') ||
            (elm.fieldType == 'SelectList' && elem.fieldType == 'RadioButton')
          ) {
            elem.visible = true
            elem.disabled = true
          }
        }
      })
    })

    setcatagory(catagory_array)
    if (res.data.statusCode == 200) {
      setcloneformdata(res.data.data[0].others)
    }
    let teamdata = JSON.parse(localStorage.getItem("Teaminfo"))
    if (Object.keys(teamdata).length > 0) {
      if (teamdata.type == "all" || teamdata.type == "channel") {
        let channeldata = teamdata.type_data.channel.map((elm) => {
          return { label: elm.name, value: elm.name }
        })

        if (channeldata.length > 0) {
          setchannel(channeldata)
        } else {
          channelfn()
        }

      }

    } else {
      channelfn()
    }
    statusfn()
  }
  function statusfn(){
    Dispatch(TicketStatus()).then((res)=>{
      if(res.data.statusCode === 200){
        let statusArray=[]
        res.data.data.forEach((item) => {
        const capitalizedLabel = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
        const capitalizedValue =  item.name.slice(0).toLowerCase()
        statusArray.push({ label: capitalizedLabel, value: capitalizedValue })
      })
      setstatus(statusArray)
      }
    })

  }
  function channelfn  ()  {
    Dispatch(GetChannel()).then((res) => {
      if (res.status == 200) {
       
        let channel = res.data.data.map((elm) => {
          return { label: elm.name, value: elm.name }
        })
        setchannel(channel)
      } else if (res.status == 204) {
        setcustomField((set) => {
          let getindexfield = set.findIndex((Elm) => Elm.model == "channel")
          if (getindexfield !== -1) {
            set[getindexfield].required = false
          }
          return set
        })
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'Source',
        module: 'lead',
      })
    });
  }
  function gettag() {

    Dispatch(TicketTagManagement())
      .then((response) => {
        if (response.data.statusCode === 200) {
          let tag = response.data.data.map((elm) => {
            return { label: elm.name, value: elm.name }
          })
          settags(tag)
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'tag',
        })
      })
  }

  const closepopup = (data) => {
    if (state_value.background.triggerdispo) {
      Dispatch(triggerdispostate(false));
    }
    setpopupdata(data)
    mounted()
  }

  const dialogopen = (data) => {
    setpopupdata((set) => {
      set.open = data
      return {
        ...set,
      }
    })
  }

  const requireclosepopup = (data) => {
    setrequiredpopupdata(data)
  }

  const requireddialogopen = (data) => {
    setrequiredpopupdata((set) => {
      set.open = data
      return {
        ...set,
      }
    })
  }

  async function update(data) {
    if (leadcreatestatus === true) {
      let emptyobj = {
        fields: [],
      }
      let countvalue = 0
      let defaultfield ={}
      let getStateId = state_value.customform.reqOption
      for (let i = 0; i < updatedata.fields.length; i++) {
        const element = updatedata.fields[i]
        const key = updatedata.fields[i].name
        if(CreateContactProp[key]){
          defaultfield[CreateContactProp[key]] = updatedata.fields[i].value
        }
        if (key === 'req_module' || key === 'req_id') {

          if (getStateId !== undefined) {
            if (getStateId.length > 0) {
              if (key === 'req_module') {
                emptyobj.fields.push({
                  name: key,
                  value: getStateId[0].name,
                })
              }
              if (key === 'req_id') {
                emptyobj.fields.push({
                  name: key,
                  value: getStateId[0].value,
                })
              }
            }
            else {
              countvalue += 1

              if (key === 'req_module') {
                emptyobj.fields.push({
                  name: key,
                  value: "contact",
                })
              }

            }
          }
        } else {
          if (key != "is_merge" && key != "is_duplicate" && key != "merged_to" && key != "merged_as" && key != "deleted")
            emptyobj.fields.push({
              name: key,
              value: updatedata.fields[i].value,
            })
        }
      }
      // if (countvalue === 0) {
      let count = 0
      for (let i = 0; i < customField.length; i++) {
        const element = customField[i]
        let tempcount = 0
        if (
          element.required === true &&
          element.visible === true &&
          element.show_on_popup === true
        ) {
          for (let j = 0; j < emptyobj.fields.length; j++) {
            const element1 = emptyobj.fields[j]
            if (element1.name === element.model) {
              let value = element1.value
              if (value === '') {
                tempcount += 1
              }
            }
          }
        }
        count += tempcount
      }

      if (count > 0) {
        setvalidate(true)
        requireddialogopen(true)
        Dispatch(triggerdispostate(true));
      } else {
        let payload = {
          template_name: campaigninfo.template_name,
          islead: "0",
          modified_by: localStorage.getItem('user'),
          fields: [ { name: "modified_by", value: localStorage.getItem('user') }, { name: "created_by", value: localStorage.getItem('user') }, { name: "created_at", value: currenttime() }, { name: "deleted", value: "false" }, { name: "modified_at", value: currenttime() }],
        };

        Object.keys(defaultfield).forEach((defaultvalue)=>{
          payload.fields.push({name : defaultvalue, value: defaultfield[defaultvalue]}) 
        })

        if(getStateId.length > 0 && getStateId[0].name === "lead"){
          await Dispatch(contactCreate(payload))
          .catch((err)=> {
            EventEmitter.emit("error-message-getter", {
              action: "create",
              module: "contact",
            });
          })
        }
        if (countvalue === 0) {
          setvalidate(false)
          Dispatch(TicketCreate(emptyobj))
            .then((res) => {
              if ((state_value.background.oncall || localStorage.getItem("wasession") === "true") && state_value.background.oncalllead[0].render < 1 && state_value.background.socket_message.phonenumber.toString() === res.data.data.phonenumber) {
                let leadData = {
                  lead_id: res.data.data.ticket_id,
                  lead_name: res.data.data.first_name,
                  phone_number: res.data.data.phonenumber,
                  module: "ticket",
                  render: 1
                }
                localStorage.setItem("oncalldata", JSON.stringify(leadData))
                Dispatch(callLeadDetail(leadData))
              }
              if(data){
                triggerCallScreenFunction(true);
              }
              navigate(`/tickets/${Encode(res.data.data.phonenumber)}/${res.data.data.ticket_id}`)
              
            })
            .catch((err) => {
              EventEmitter.emit('error-message-getter', {
                action: 'create',
                module: "ticket",
              })
            })
        }
        else {

          // let payload = {
          //   template_name: campaigninfo.template_name,
          //   islead: "0",
          //   modified_by: localStorage.getItem('user'),
          //   fields: [ { name: "modified_by", value: localStorage.getItem('user') }, { name: "created_by", value: localStorage.getItem('user') }, { name: "created_at", value: currenttime() }, { name: "deleted", value: "false" }, { name: "modified_at", value: currenttime() }],
          // };
          Dispatch(contactCreate(payload))
            .then((res) => {
              let count = 0
              for (let i = 0; i < customField.length; i++) {
                const element = customField[i]
                let tempcount = 0
                if (
                  element.required === true &&
                  element.visible === true &&
                  element.show_on_popup === true
                ) {
                  for (let j = 0; j < emptyobj.fields.length; j++) {
                    const element1 = emptyobj.fields[j]
                    if (element1.name === element.model) {
                      let value = element1.value
                      if (value === '') {
                        tempcount += 1
                      }
                    }
                  }
                }
                if (element.model === "req_id") {
                  emptyobj.fields.push({
                    name: "req_id",
                    value: res.data.data.lead_id,
                  })
                }
                count += tempcount
              }

              if (count > 0) {
                setvalidate(true)
                requireddialogopen(true)
                Dispatch(triggerdispostate(true));
              } else {
                setvalidate(false)
                Dispatch(TicketCreate(emptyobj))
                  .then((res) => {
                    if ((state_value.background.oncall || localStorage.getItem("wasession") === "true") && state_value.background.oncalllead[0].render < 1 && state_value.background.socket_message.phonenumber.toString() === res.data.data.phonenumber) {
                      let leadData = {
                        lead_id: res.data.data.ticket_id,
                        lead_name: res.data.data.first_name,
                        phone_number: res.data.data.phonenumber,
                        module: "ticket",
                        render: 1
                      }

                      Dispatch(callLeadDetail(leadData))
                    }
                    if(data){
                      triggerCallScreenFunction(true);
                    }
                    navigate(`/tickets/${Encode(res.data.data.phonenumber)}/${res.data.data.ticket_id}`)
                    
                  })
                  .catch((err) => {
                    EventEmitter.emit('error-message-getter', {
                      action: 'create',
                      module: "ticket",
                    })
                  })
              }
            }).catch((err) => {
             
              EventEmitter.emit("error-message-getter", {
                action: "create",
                module: "contact",
              });
            });


        }
      }
    } else {
      let count = 0
      for (let i = 0; i < customField.length; i++) {
        const element = customField[i]
        let tempcount = 0
        if (
          element.required === true &&
          element.visible === true &&
          element.show_on_popup === true
        ) {
          for (let j = 0; j < updatedata.fields.length; j++) {
            const element1 = updatedata.fields[j]
            if (element1.name === element.model) {
              let value = element1.value
              if (value === '') {
                tempcount += 1
              }
            }
          }
        }
        count += tempcount
      }
      // updatedata.fields.forEach((elm)=>{
      //   if(elm.name ==  "sms_notify"){
      //     elm.value = '0'
      //   }
      //   if(elm.name == "wa_notify"){
      //     elm.value = '0'
      //   }
      //   if(elm.name == "email_notify"){
      //     elm.value = '0'
      //   }
      //   if(elm.name == "api_push"){
      //     elm.value = '0'
      //   }
      // })
      if (count > 0) {
        setvalidate(true)
        requireddialogopen(true)
        Dispatch(triggerdispostate(true));
      } else {
        setvalidate(false)
        Dispatch(TicketUpdate(ticketid, updatedata))
          .then(function (res) {
            if(data===undefined){
              dialogopen(true);
            }
            
            props.fetchapi()
            if(data){
              triggerCallScreenFunction(true);
            }
          })
          .catch((err) => {
            EventEmitter.emit('error-message-getter', {
              action: 'update',
              module: 'ticket',
            })
          })
      }
    }

    props.saveleaddetail(false)
  }

  const linkedupadate = (linkedres, parentval, selectedval) => {
    if (linkedres.length > 0) {
      let filterdata
      linkedres.forEach((elem) => {
        filterdata = customField.map((elm) => {
          if (elm.model == elem.model) {
            if (parentval.fieldType == 'RadioButton') {
              elm.disabled = false
              elm.visible = true
            } else if (parentval.fieldType == 'Checkbox') {
              if (selectedval.checked == 'true') {
                elm.disabled = false
                elm.visible = true
              } else {
                if (elm.link_property.hasOwnProperty('map') != true) {
                  elm.disabled = true
                } else {
                  elm.visible = false
                }
                formdata[`${elm.model}`] = ''
              }
            } else if (parentval.fieldType == 'SelectList') {
              elm.disabled = false
              elm.visible = true
            }

            if (elm.link_property.hasOwnProperty('map') != true) {
              for (const property in elm.link_property) {
                if (selectedval.value === property) {
                  let split_linkedprop = elm.link_property[property].split(',')
                  let update_linked = split_linkedprop.map((elm) => {
                    return { name: elm, value: elm }
                  })
                  elm.values = [JSON.stringify(update_linked)]
                }
              }
            }
          }
          return elm
        })
      })
      setcustomField(filterdata)
    }
  }

  function handleChangedata(parentval, data) {
    if (
      parentval.link_type == 'Parent' &&
      parentval.linked_to == '' &&
      parentval.is_link == true
    ) {
      let linkedres = customField.filter((elm) => {
        if (elm.linked_to == parentval.model) {

          if (
            elm.link_property.map !== data.value &&
            elm.link_property.hasOwnProperty('map')
          ) {
            elm.visible = false
            formdata[`${elm.model}`] = ''
          } else if (elm.link_property.map == data.value) {
            return elm
          } else if (elm.link_property.hasOwnProperty('map') != true) {
            return elm
          }
        }
      })
      linkedupadate(linkedres, parentval, data)
    }

    setformdata((set) => {
      if (parentval.fieldType == 'RadioButton') {
        set[`${parentval.model}`] = data.value
      } else if (parentval.fieldType == 'Checkbox') {
        set[`${parentval.model}`] = data.checked
      } else if (
        parentval.fieldType == 'SelectList' &&
        parentval.multiple == true
      ) {
        if (Array.isArray(data)) {
          if (parentval.readonly) {
            set[`${parentval.model}`] = set[`${parentval.model}`]
          } else {
            let mergeselect_vals = data.map((elm) => elm.value).toString()
            set[`${parentval.model}`] = mergeselect_vals
          }
        } else {
          set[`${parentval.model}`] = data.value
        }
      } else {
        set[`${parentval.model}`] = data.value
      }

      return {
        ...set,
      }
    })
  }
  function gettextareavalue(value, model) {
    let count = value.split("").length
    if (255 - count <= 255 && 255 - count >= 0) {
      settextcount((set) => {
        set[`${model}`] = 255 - count
        return {
          ...set
        }
      })
      setformdata((set) => {
        set[`${model}`] = value;
        return { ...set };
      })
    }
  }
  let textinputchange = (parentval, data) => {
    if (parentval.type == "input") {
      parentval.type = "others"
    }
    let val = Regex_(parentval.type)
    let otherval = false
    if (parentval.type === "others") {
      if (parentval.model_type === "SmallNumber" || parentval.model_type === "Number" || parentval.model_type === "BigNumber") {
        val = Regex_("number")
      } else {
        otherval = true
      }
    }


    if (data.value === "" || (parentval.type === "others" && otherval)) {
      regexvalidation[`${parentval.model}`] = false
      setregexvalidation((set) => {
        set[`${parentval.model}`] = false
        return {
          ...set
        }
      })
      props.regexval(Object.values(regexvalidation).every((condition) =>
        condition === false
      ))
    }
    else if (!val.test(data.value)) {
      regexvalidation[`${parentval.model}`] = true
      setregexvalidation((set) => {
        set[`${parentval.model}`] = true
        return {
          ...set
        }
      })
      props.regexval(Object.values(regexvalidation).every((condition) =>
        condition === false
      ))
    } else {
      regexvalidation[`${parentval.model}`] = false
      setregexvalidation((set) => {
        set[`${parentval.model}`] = false
        return {
          ...set
        }
      })
      props.regexval(Object.values(regexvalidation).every((condition) =>
        condition === false
      ))
    }
    setformdata((set) => {
      set[`${parentval.model}`] = data.value

      return {
        ...set,
      }
    })
    if (parentval.model === 'phone_number') {
      Dispatch(TicketGetRequestModule(data.value, campaigninfo.template_name))
    }
  }

  let SelectedGetData = (val) => {
    let select_options = JSON.parse(val.values)
    if (val.multiple != true) {
      if (
        formdata[`${val.model}`] === '' ||
        formdata[`${val.model}`] === null
      ) {
        return null
      } else {
        if (val.model == "channel") {
          let listdatas = channel.find(
            (elm) => elm.value == formdata[`${val.model}`]
          );
          if(listdatas !== undefined){
            return {
              value: listdatas.value,
              label: listdatas.label,
            };
          }else{
            return null;
          }
        } else {
          let res_val = select_options.find(
            (elm) => elm.value == formdata[`${val.model}`],
          )
          if (res_val == undefined) {
            return null
          } else {
            return {
              value: res_val.value,
              label: res_val.name,
            }
          }
        }
      }
    } else {
      let final_res
      if (
        formdata[`${val.model}`] !== '' &&
        formdata &&
        Object.keys(formdata).length > 0
      ) {
        let splited_data = formdata[`${val.model}`].split(',')
        final_res = splited_data.map((elem) => {
          if (val.model === 'tags') {
            let res_val = tags.find((elm) => elm.value == elem)
            if (res_val == undefined) {
              return null
            } else {
              return {
                value: res_val.value,
                label: res_val.label,
              }
            }
          } else {
            let res_val = select_options.find((elm) => elm.value == elem)
            if (res_val == undefined) {
              return null
            } else {
              return {
                value: res_val.value, //    created at , phone,ticket id  ,priority,status
                label: res_val.name,
              }
            }
          }
        })
      } else {
        final_res = []
      }

      return final_res
    }
  }

  let DateGetData = (type, model) => {
    if (type == 'DatePicker') {
      if (formdata[model] == null || formdata[model] == '') {
        return formdata[model]
      } else {
        if (formdata[model] == '0000-00-00 00:00:00') {
          return null;
        }
        else if (formdata[model] == '0000-00-00') {
          return null;
        } else {
          let myDate = new Date(formdata[model])
          myDate.setHours(5, 30, 0);
          return myDate
        }

      }
    } else if (type == 'TimePicker') {
      return formdata[model]
    }
  }

  function setuserdate(val, fieldata) {

    const today = val
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1
    let dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formattedToday = yyyy + '-' + mm + '-' + dd
    setformdata((set) => {
      set[`${fieldata.model}`] = formattedToday

      return {
        ...set,
      }
    })
  }

  const onTimeChanges = (val, fieldata) => {
    let { hour, minute, meridiem } = val
    let timevalue = `${hour}:${minute}`

    setformdata((set) => {
      set[`${fieldata.model}`] = timevalue

      return {
        ...set,
      }
    })
  }
  const DateTimeGetData = (datetime_return, model) => {

    if (datetime_return) {
        if (!formdata[model] || formdata[model] === '' || formdata[model] === '0000-00-00 00:00:00') {
            return null;
        } else {
            const dateTime = moment(formdata[model], 'YYYY-MM-DD HH:mm:ss');
            if (!dateTime.isValid()) {
                console.log("DateTimeGetData: Invalid date format");
                return null;
            } else {
                const formattedTime = dateTime.format('HH:mm');
                return formattedTime;
            }
        }
    } else {
        if (!formdata[model] || formdata[model] === '' || formdata[model] === '0000-00-00 00:00:00') {
            return new Date();
        } else {
            const dateTime = moment(formdata[model], 'YYYY-MM-DD HH:mm:ss');
            if (!dateTime.isValid()) {
                console.log("DateTimeGetData: Invalid date format");
                return null;
            } else {
                return dateTime.toDate();
            }
        }
    }
  };


  const onDateTimeChanges = (datetime_return, data_val, fieldval) => {

    if (datetime_return) {
      let datetime = new Date()
      datetime.setHours(parseInt(data_val.hour), parseInt(data_val.minute), 0)

      if (formdata[fieldval.model] == '' || formdata[fieldval.model] == null) {
        setformdata((set) => {
          set[`${fieldval.model}`] = datetime.toISOString()

          return {
            ...set,
          }
        })
      } else {
        let updatetime = new Date(formdata[fieldval.model])
        updatetime.setHours(
          parseInt(data_val.hour),
          parseInt(data_val.minute),
          0,
        )
        setformdata((set) => {
          set[`${fieldval.model}`] = datetime.toISOString()

          return {
            ...set,
          }
        })
      }
    } else {
      const yyyy = data_val.getFullYear()
      let mm = data_val.getMonth() + 1
      let dd = data_val.getDate()

      if (dd < 10) dd = '0' + dd
      if (mm < 10) mm = '0' + mm

      const formattedToday = yyyy + '-' + mm + '-' + dd
      if (formdata[fieldval.model] == '' || formdata[fieldval.model] == null) {
        handleChangedata(fieldval, { value:data_val })
      } else {
        let updatetime = new Date(formdata[fieldval.model])
        updatetime.setFullYear(yyyy, mm, dd)
        handleChangedata(fieldval, { value:data_val })
      }
    }
  }

  function objectConverter(val) {

    let arr = JSON.parse(val.values)
    if (val.fieldType === 'RadioButton') {
      return (
        <>
          {arr.map((res, i) => {
            return (
              <Form.Check
                key={val.model + i + Math.round(Math.random() * 100).toString()}
                value={formdata[`${val.model}`]}
                disabled={val.disabled || val.readonly}
                onChange={() => handleChangedata(val, res)}
                checked={res.value === formdata[`${val.model}`]}
                type="radio"
                label={res.name}
              />
            )

          })}
        </>
      )
    } else if (val.fieldType === 'Checkbox') {
      let val_ = formdata[`${val.model}`]
      return (
        <Form.Check
          key={val.model + Math.round(Math.random() * 100).toString()}
          value={formdata[`${val.model}`]}
          disabled={val.disabled || val.readonly}
          onChange={(e) =>
            handleChangedata(val, {
              checked: e.target.checked.toString(),
              value: val.model,
            })
          }
          type="checkbox"
          checked={val_ == 'true'}
          label={`${val.label}`}
        />
      )

    } else {
      let default_value = []
      let option_arr = []

      for (let i = 0; i < arr.length; i++) {
        const element = arr[i]
        let obj = {}
        let default_val = {}
        obj['value'] = element.value
        obj['label'] = element.name
        option_arr.push(obj)
        if (formdata[`${val.model}`] === element.value) {
          default_val['value'] = element.value
          default_val['label'] = element.name
          default_value.push(default_val)
        }
      }
      return (
        <>
          <Select
            key={val.model + Math.round(Math.random() * 100).toString()}
            isMulti={val.multiple}
            isDisabled={val.disabled}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            menuShouldScrollIntoView={true}
            menuPlacement={`auto`}
            onChange={(e) => handleChangedata(val, e)}
            value={SelectedGetData(val)}
            options={val.model == 'tags' ? tags : val.model == 'channel' ? channel : val.model=='status' ? status: option_arr}
            isSearchable={true && val.readonly ? false : true}
            menuIsOpen={val.readonly ? false : undefined}
            placeholder={val.placeholder}
          />
        </>
      )
    }
  }

  function timer(data) {
    let val = data.val
    let datetime = data.datetime
    if (datetime) {

      onDateTimeChanges(true, data, val)
    } else {
      onTimeChanges(data, val)
    }
  }
  function Datelimit(type,prop){
    if(type == "min" && prop !== null){
      if(prop.value == "all_date" || prop.value == "past_dates"){
        return ""
      }else if(prop.value == "future_dates"){
        return new Date()
      }
    }else if(type == "max" && prop !== null){
      if(prop.value == "all_date" || prop.value == "past_dates" || prop.value == "future_dates"){
        return ""
      }
    }else{
      return ""
    }
  }
  function openchatbox(data){
    console.log(data,"datadatadatadatadatadatadatadata")
    setchatdata(data)
    }
    function closeMsgpopup(){
      setchatdata({})
    }
 
  return (
    <div>
      <div className="Customform-main-view">
        <>
          {catagory.map((resp, i) => {
            return (
              <div>
                {resp && (
                  <>
                    <div
                      className="Customform-catagory"
                      key={resp + i.toString()}
                    >
                      <div className="Customform-catagory-lft">
                        <div
                          className="Customform-category-icon"
                          key={resp + i + i.toString()}
                        >
                          {' '}
                          <BsFillHexagonFill />
                        </div>
                        <div
                          className="Customform-category-title"
                          key={resp + i + i + i.toString()}
                        >
                          {resp}
                        </div>
                      </div>

                    </div>
                    <div
                      className="Customform-body"
                      key={resp + i + i + i + i.toString()}
                    >
                      {customField.filter(res => res.model !== 'ticket_id')
                      .map((res, i) => {
                        return (
                          <>
                            {resp === res.catagory_value &&
                              res.visible == true &&
                              res.show_on_popup && (
                                <div
                                  className="Customform-fields"
                                  key={res.model}
                                >
                                  {res.fieldType == 'SelectList' && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? 'label_cls required'
                                              : res.readonly
                                                ? 'label_cls readonly'
                                                : 'label_cls'
                                          }
                                        >
                                          {res.label}{' '}
                                        </label>
                                        {objectConverter(res)}
                                        {res.required &&
                                          formdata[`${res.model}`] == '' &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}

                                  {res.fieldType == 'TextInput' && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? 'label_cls required'
                                              : res.readonly
                                                ? 'label_cls readonly'
                                                : 'label_cls'
                                          }
                                        >
                                          {res.label}
                                        </label>
                                        {location.pathname !== '/tickets/create'&& res.type==="phone_number"&&AppPermission.customform_msg&&   <CiChat2 onClick={()=>{openchatbox(res)}} />}
                                        <Form.Control
                                          key={res.model + i + i + i.toString()}
                                          disabled={res.disabled}
                                          onChange={(e) =>
                                            textinputchange(res, {
                                              value: e.target.value,
                                            })
                                          }
                                          value={
                                            formdata[`${res.model}`] ===
                                              undefined
                                              ? ''
                                              : (res.model === 'phone_number' && !props.operation)  || (res.model === 'phone_number' && props.operation && localStorage.getItem(`setStatic_number`) !== null && "" != formdata[`${res.model}`]) || (res.model === 'phone_number' && props.operation && flagnumber)  ? fromat(formdata[`${res.model}`], { maskbool: true }) : formdata[`${res.model}`]
                                          }
                                          readOnly={(state_value.background.oncall || flagnumber) && res.model === 'phone_number' ? true : localStorage.getItem(`setStatic_number`) !== null && res.model === 'phone_number' ? true : res.readonly}
                                          className="form-control"
                                          placeholder={res.placeholder}
                                        />
                                        {res.required &&
                                          (formdata[`${res.model}`] == '' ||  formdata[`${res.model}`] ===
                                          undefined) &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                        {regexvalidation[`${res.model}`] && (
                                          <span className="require_class">
                                            Please Enter Valid {Format('format')}
                                          </span>
                                        )}
                                      </div>
                                    </>
                                  )}
                                  {res.fieldType == 'LongTextInput' && (
                                    <>
                                      <label
                                        key={res.model + i.toString()}
                                        className={
                                          res.required
                                            ? 'label_cls required'
                                            : res.readonly
                                              ? 'label_cls readonly'
                                              : 'label_cls'
                                        }
                                      >
                                        {res.label}
                                      </label>
                                      <Form.Control
                                        key={res.model}
                                        disabled={res.disabled}
                                        readOnly={res.readonly}
                                        onChange={(e) =>
                                          gettextareavalue(e.target.value, res.model)
                                        }
                                        value={
                                          formdata[`${res.model}`] === undefined
                                            ? ''
                                            : formdata[`${res.model}`]
                                        }
                                        as="textarea"
                                        placeholder={res.placeholder}
                                      />
                                      {res.required &&
                                        formdata[`${res.model}`] == '' &&
                                        validate && (
                                          <span className="require_class">
                                            Please Fill Required Field
                                          </span>
                                        )}
                                      {textcount[`${res.model}`] === 0 && (
                                        <span className="require_class">
                                          Max Characters 255 Reached
                                        </span>
                                      )}
                                    </>
                                  )}
                                  {res.fieldType == 'RadioButton' && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >

                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? 'label_cls required'
                                              : res.readonly
                                                ? 'label_cls readonly'
                                                : 'label_cls'
                                          }
                                        >
                                          {res.label}{' '}
                                        </label>
                                        {objectConverter(res)}
                                        {res.required &&
                                          formdata[`${res.model}`] == '' &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                  {res.fieldType == 'Checkbox' && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? 'label_cls required'
                                              : res.readonly
                                                ? 'label_cls readonly'
                                                : 'label_cls'
                                          }
                                        >
                                          {res.label}{' '}
                                        </label>
                                        {objectConverter(res)}
                                        {res.required &&
                                          formdata[`${res.model}`] == '' &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                  {res.fieldType == 'DatePicker' && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? 'label_cls required'
                                              : res.readonly
                                                ? 'label_cls readonly'
                                                : 'label_cls'
                                          }
                                        >
                                          {res.label}
                                        </label>

                                        <DatePicker
                                          key={res.model + i + i + i.toString()}
                                          disabled={res.disabled}
                                          readOnly={res.readonly}
                                          dateFormat="dd/MM/yyyy"
                                          selected={DateGetData(
                                            res.fieldType,
                                            res.model,
                                          )}
                                          onChange={(date_value) =>
                                            setuserdate(date_value, res)
                                          }
                                          placeholderText={res.placeholder}
                                          className="form-control"
                                          minDate={res.is_limit == true ? Datelimit("min",res.limit_property)   :""}
                                          maxDate={res.is_limit == true ? Datelimit("mix",res.limit_property)  :""}
                                        />
                                        {res.required &&
                                          formdata[`${res.model}`] == '' &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                  {res.fieldType == 'TimePicker' && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? 'label_cls required'
                                              : res.readonly
                                                ? 'label_cls readonly'
                                                : 'label_cls'
                                          }
                                        >
                                          {res.label} {`(HH:MM)`}
                                        </label>
                                        <Timer
                                          key={res.model + i + i + i.toString()}
                                          currentvalue={DateGetData(
                                            res.fieldType,
                                            res.model,
                                          )}
                                          disabled={res.disabled}
                                          readOnly={res.readonly}
                                          valuegetter={res}
                                          datetime={false}
                                          minutefinaldata={timer}
                                          hoursfinaldata={timer}
                                        ></Timer>
                                        {res.required &&
                                          formdata[`${res.model}`] == '' &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                  {res.fieldType == 'DatetimePicker' && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? 'label_cls required'
                                              : res.readonly
                                                ? 'label_cls readonly'
                                                : 'label_cls'
                                          }
                                        >
                                          {res.label} {`(HH:MM DD/MM/YYYY)`}
                                        </label>
                                        <div
                                          className="DatetimePicker"
                                          key={res.model + i + i + i.toString()}
                                        >
                                          {/* <div
                                            className="Datetime_container"
                                            key={
                                              res.model +
                                              i +
                                              i +
                                              i +
                                              i.toString()
                                            }
                                          >
                                            <Timer
                                              key={
                                                res.model +
                                                i +
                                                i +
                                                i +
                                                i +
                                                i.toString()
                                              }
                                              currentvalue={DateTimeGetData(
                                                true,
                                                res.model,
                                              )}
                                              disabled={res.disabled}
                                              readOnly={res.readonly}
                                              valuegetter={res}
                                              datetime={true}
                                              minutefinaldata={timer}
                                              hoursfinaldata={timer}
                                            ></Timer>
                                          </div> */}
                                          <div
                                            className="Datetime_container"
                                            key={
                                              res.model +
                                              i +
                                              i +
                                              i +
                                              i +
                                              i +
                                              i.toString()
                                            }
                                          >
                                            <DatePicker
                                              key={
                                                res.model +
                                                i +
                                                i +
                                                i +
                                                i +
                                                i +
                                                i +
                                                i.toString()
                                              }
                                              showTimeSelect
                                              timeIntervals={1}
                                              disabled={res.disabled}
                                              readOnly={res.readonly}
                                              dateFormat="yyyy/MM/d HH:mm"
                                              selected={DateTimeGetData(
                                                false,
                                                res.model,
                                              )}
                                              onChange={(date_value) =>
                                                onDateTimeChanges(
                                                  false,
                                                  date_value,
                                                  res,
                                                )
                                              }
                                              timeFormat="HH:mm"
                                              placeholderText={res.placeholder}
                                              className="form-control"
                                            />
                                          </div>
                                        </div>
                                        {res.required &&
                                          formdata[`${res.model}`] == '' &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                          </>
                        )
                      })}
                    </div>
                  </>
                )}
              </div>
            )
          })}
        </>
      </div>
      <ChatBox formdata={formdata} chatdata={chatdata} closeMsgpopup={closeMsgpopup}></ChatBox>
      <Dialogbox
        fields={requireclosepopup}
        value={requiredpopupdata}
        defaultclass={'dialog-body-default'}
      />
      <Dialogbox
        fields={closepopup}
        value={popupdata}
        defaultclass={'dialog-body-default'}
      />
    </div>
  )
}

export default React.forwardRef(CustomForm)
