import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Carousel, Spinner } from 'react-bootstrap';
import '../assets/css/App.css';
import '../assets/css/style-response.css'
import gtawk from '../assets/images/gtawk.png'
import logo from '../assets/images/logo.png';
import Skyfall from '../assets/images/SKyfall logo.png'
import google from "../assets/images/google.png";
import clogo from "../assets/images/image_3.png";
import img from '../assets/images/mfa_illustration2x.png';
import img1 from '../assets/images/recovery_illustration2x.png';
import { setLogin } from '../redux/Login/LoginAction';
import { setCampaign, setUser, SetDid } from '../redux/Details/DetailAction';
import { connect, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import localdomain from "../utils/constant"
import EventEmitter from '../utils/EventEmitter';
import { Asterlogin, AsterCampaigns, AsterConfig, LoginUserinfo, AuthUserinfo, AsterPermission } from "../redux/actions/login"
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Encode } from '../utils/EncodeDecode'
import { Decode } from '../utils/EncodeDecode'
// import LoadingSpinner from '../components/LoadingSpinner'
import FullPageLoader from '../components/FullPageLoader'

const auth0Config = localdomain();
const common_api_url = auth0Config.login;
const url = new URL(window.location.href);
let loginToken = false;
console.log("token is ", loginToken);
function Login(props) {
  let dispatch = useDispatch()
  let navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const pathname = window.location.pathname;
  const [path, setPath] = useState(pathname)
  const [isAutoLoginAttempted, setIsAutoLoginAttempted] = useState(false);

  useEffect(() => {
    const cleanupStorage = () => {
      localStorage.clear();

    };

    const handleAutoLogin = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const loginData = urlParams.get('token');

      if (loginData) {
        setLoading(true);
        try {
          const decodedData = JSON.parse(Decode(loginData));
          const email = decodedData.username;
          const password = decodedData.password

          const response = await dispatch(Asterlogin({ username: email, password }));
          if (response.data.statusCode === 200) {
            await handleLoginSuccess(response, email, password);
          } else if (response.data.statusCode === 403) {
            setLoading(false)
            EventEmitter.emit('error-message-getter', {
              action: 'invalid',
            })
            url.searchParams.delete('token')
            loginToken = false
            localStorage.clear();
            navigate('/')
          }
          else {
            url.searchParams.delete('token')
            loginToken = false
            localStorage.clear();
            navigate('/')
            throw new Error('Auto-login failed');
          }
        } catch (error) {
          url.searchParams.delete('token')
          loginToken = false
          localStorage.clear();
          navigate('/')
          console.error('Auto-login error:', error);
          EventEmitter.emit('error-message-getter', {
            action: 'auto-login-failed',
            module: 'login',
          });
        } finally {
          setLoading(false);
        }
      } else {
        loginToken = false
        localStorage.clear();
        navigate('/')
      }
    };
    if (localStorage.getItem('apinfo') === null || localStorage.getItem('extension') === null) {
      const revertstatus = localStorage.getItem("revertload");
      loginToken = url.searchParams.get('token') || false

      if (revertstatus !== null && revertstatus === "true") {
        localStorage.removeItem('revertload')
        window.location.reload();
      } else if (loginToken) {
        handleAutoLogin();
      } else {
        if (localStorage.getItem('apinfo') === null && localStorage.getItem('extension') === null) {
          cleanupStorage();
        }

      }
    } else {
      loginToken = false
      navigate('/dashboard');
    }
  }, []);


  const handleLoginSuccess = async (response, email, password) => {
    const tok = {
      loggedIn: true,
      token: response.data.access_token
    };
    props.set_login(tok);

    const decoded = jwtDecode(response.data.access_token);
    const payloadData = {
      tenant_code: `${decoded['http://api.doocti.com/claims/tenant_code']}`,
      user_email: email
    };

    try {
      const configRes = await dispatch(AsterConfig(payloadData));
      if (configRes.status === 200) {
        const apinfo = createApInfo(decoded, configRes, response.data.access_token);
        localStorage.setItem("apinfo", JSON.stringify(apinfo));
        localStorage.setItem("config", JSON.stringify(configRes));

        await setupUserAndCampaigns(email, apinfo);
        routing(apinfo.AuthUser, configRes);
      }
    } catch (error) {
      console.error('Error in login process:', error);
      setLoading(false);
      EventEmitter.emit('error-message-getter', {
        action: 'login-process-failed',
        module: 'login',
      });
    }
  };

  const createApInfo = (decoded, configRes, token) => {
    const domains = configRes.data.projects[0].config[0];
    return {
      tenant_code: `${decoded['http://api.doocti.com/claims/tenant_code']}`,
      api_url: 'https://' + domains.api_domain,
      dialer_url: 'https://' + domains.dialer_domain,
      portal_url: 'https://' + domains.portal_domain,
      token: token,
      socket_url: 'https://' + domains.socket_domain,
      AuthUser: `${decoded[`aud`][1]}`,
      sub_version: configRes.data.projects[0].config[0].sub_version
    };
  };

  const setupUserAndCampaigns = async (email, apinfo) => {
    const campaignsRes = await dispatch(AsterCampaigns(email, apinfo));
    const campaigns = campaignsRes.data.data.map(element => element.name);
    props.set_campaign(campaigns);
    props.set_user(email);
    localStorage.setItem("Teaminfo", JSON.stringify(campaignsRes.data.Teaminfo));

    const permissionsRes = await dispatch(AsterPermission());
    localStorage.setItem('permissions', JSON.stringify(permissionsRes.data.data.permissions));
  };


  const routing = (data, Permissions, FormloginData) => {
    let apiinfo = JSON.parse(localStorage.getItem('apinfo')) || {};
    let sub_version = apiinfo.sub_version;
    const currentVersion = process.env.PUBLIC_URL || '/';

    let AppPermissions = Permissions.data.projects[0].userPermission;

    function normalizeVersion(version) {
      console.log("Normalizing version:", version);
      if (!version || version === '/') {
        return '/';
      }
      return version.startsWith('/') ? version : `/${version}`;
    }

    function handleVersionRedirection(subVersion, loginData) {
      const domainName = window.location.hostname;
      const port = window.location.port;
      let redirectUrl;
      // Remove leading and trailing slashes from subVersion
      const cleanSubVersion = subVersion.replace(/^\/|\/$/g, '');
      if (domainName === "localhost") {
        const redirectPort = port === '3000' ? '3001' : '3000';
        redirectUrl = `http://localhost:${redirectPort}${cleanSubVersion ? `/${cleanSubVersion}` : ''}/`;
        console.log(`Redirecting to: ${redirectUrl}`);
      } else {
        redirectUrl = `https://${domainName}${cleanSubVersion ? `/${cleanSubVersion}` : ''}/`;
      }

      const encodedLoginData = Encode(JSON.stringify(loginData));
      redirectUrl += `?token=${encodedLoginData}`;

      console.log(`Final Redirect URL: ${redirectUrl}`);
      localStorage.clear();
      window.location.href = redirectUrl;
    }

    const handleAuthSuccess = (response) => {
      if (response.data.name !== "") {
        let payload = {
          avatar_url: response.data.picture,
          name: response.data.nickname,
          user: response.data.name
        };

        localStorage.setItem("AuthUserinfo", JSON.stringify(payload));
        localStorage.setItem("username", response.data.nickname);
        localStorage.setItem("callcontroll", JSON.stringify({
          mute: false,
          hold: false,
          transfer: false,
          conf: false,
          dialpad: false
        }));

        const normalizedSubVersion = normalizeVersion(sub_version);
        const normalizedCurrentVersion = normalizeVersion(currentVersion);

        console.log(`::subversion and current version::`, normalizedSubVersion, normalizedCurrentVersion);

        const shouldDirectRoute = normalizedSubVersion === normalizedCurrentVersion || !normalizedSubVersion
        if (normalizedSubVersion !== normalizedCurrentVersion && FormloginData && !shouldDirectRoute) {
          console.log("::subversion and current version not same::", normalizedSubVersion, normalizedCurrentVersion);
          handleVersionRedirection(normalizedSubVersion, FormloginData);
        } else {
          console.log("::Direct routing or other scenarios::", normalizedSubVersion, normalizedCurrentVersion);
          loginToken = false;
          navigate('/select');
        }
      }
    };

    dispatch(AuthUserinfo(data))
      .then(handleAuthSuccess)
      .catch((err) => {
        setLoading(false);
        EventEmitter.emit('error-message-getter', {
          action: 'login',
          module: 'login',
        });
      });
  };
  const handleEmailChange = (value) => {
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value.trim() === '') {
      setEmailError('');
    } else {
      setEmailError(emailRegex.test(value) ? '' : 'Please enter the required format');
    }
  };
  const handleSubmit = async (event) => {
    setLoading(true)
    const email = event.target[0].value.toLowerCase();
    const password = event.target[1].value;
    event.preventDefault();

    if (email === '' || !email || emailError || !password) {
      setLoading(false)
      EventEmitter.emit('error-message-getter', {
        action: 'email',
        module: 'email',
      })
    }

    else if (password === '') {
      setLoading(false)
      EventEmitter.emit('error-message-getter', {
        action: 'password',
        module: 'password',
      })
    }
    else {
      let payload = {
        username: email,
        password: password
      }
      dispatch(Asterlogin(payload))
        .then(function (response) {

          if (response.data.statusCode === 200) {
            // setLoading(false)
            const tok = {
              loggedIn: true,
              token: response.data.access_token
            }
            props.set_login(tok);
            const decoded = jwtDecode(response.data.access_token);
            let payloadData = {};
            payloadData['tenant_code'] = `${decoded['http://api.doocti.com/claims/tenant_code']}`
            payloadData['user_email'] = email
            dispatch(AsterConfig(payloadData))
              .then(function (res) {
                if (res.status === 200) {
                  let domains = res.data.projects[0].config[0]
                  console.log(domains, "eeeeeeeeeeeeeeeeeee")
                  let apinfo = {
                    tenant_code: `${decoded['http://api.doocti.com/claims/tenant_code']}`,
                    api_url: 'https://' + domains.api_domain,
                    dialer_url: 'https://' + domains.dialer_domain,
                    portal_url: 'https://' + domains.portal_domain,
                    token: response.data.access_token,
                    socket_url: 'https://' + domains.socket_domain,
                    AuthUser: `${decoded[`aud`][1]}`,
                    sub_version: res.data.projects[0].config[0].sub_version   //hi
                  }
                  console.log(apinfo, "eeeeeeeeeeeeeeeeeee")
                  localStorage.setItem("apinfo", JSON.stringify(apinfo))
                  let data = JSON.stringify(res)
                  localStorage.setItem("config", data)
                  dispatch(AsterCampaigns(email, apinfo))
                    .then(function (res__) {
                      var temp = res__.data.data;
                      var campaigns = [];
                      dispatch(AsterPermission()).then(function (data) {
                        localStorage.setItem('permissions', JSON.stringify(data.data.data.permissions))
                      }).catch((err) => { })
                      temp.forEach(element => {
                        campaigns.push(element.name);
                      });
                      props.set_campaign(campaigns);
                      props.set_user(email);

                      localStorage.setItem("Teaminfo", JSON.stringify(res__.data.Teaminfo))
                      routing(apinfo.AuthUser, res, payload)
                    })
                    .catch((err) => {
                      setLoading(false)
                      EventEmitter.emit('error-message-getter', {
                        action: 'fetch',
                        module: 'campaign',
                      })
                    })

                }
              })
          }
          else if (response.data.statusCode === 403) {
            setLoading(false)
            EventEmitter.emit('error-message-getter', {
              action: 'invalid',
            })
            loginToken = false
            localStorage.clear();
            navigate('/')
          }
        })
        .catch((err) => {
          setLoading(false)
          EventEmitter.emit('error-message-getter', {
            action: 'invalid',
          })

        });
    }
  }
  const domain_name = () => {
    const regex = /doocti/g;
    const matches = window.location.hostname.match(regex);
    if (matches !== null && matches[0] === "doocti") {
      return true
    }
    return false
  }

  return (
    <>
      {loginToken ? (
        <FullPageLoader />
      ) : (
        <div className='mainlogin'>
          <Col className='lftlogin mobile_hide' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

            <Carousel>
              <Carousel.Item interval={1000}>
                <img src={img} alt="First slide" />
                <Carousel.Caption>
                  <h3>Social Media Is Here</h3>
                  <p>Manage Customer Queries Across Channels Effortlessly</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={500}>
                <img src={img1} alt="Second slide" />
                <Carousel.Caption>
                  <h3> Inbuild CRM With Cloud Telephony</h3>
                  <p>Manage Your Sales And Support Process</p>
                </Carousel.Caption>
              </Carousel.Item>
              {/* <Carousel.Item interval={500}>
                            <img

                                src={img}
                                alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h3>Third slide label</h3>
                                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                            </Carousel.Caption>
                        </Carousel.Item> */}
            </Carousel>
          </Col>
          <div className="rhtlogin">
            

             
              <div className="d-flex flex-column align-items-center">
                <div style={{ textAlign: "left", "backdrop-filter": "blur(5px)" }}>
                  {domain_name() && (
                    <h3 style={{ "text-align": "center" }}>
                      <img src={logo} alt="Text" style={{ maxWidth: 180 }} />
                    </h3>
                  )}
                  {domain_name() === false &&
                    window.location.hostname.match("web.gtawk") && (
                      <h3 style={{ "text-align": "center" }}>
                        <img src={gtawk} alt="Text" style={{ maxWidth: 180 }} />
                      </h3>
                    )}
                  {domain_name() === false &&
                    window.location.hostname.match("web-dev.doocti") && (
                      <h3 style={{ "text-align": "center" }}>
                        <img src={logo} alt="Text" style={{ maxWidth: 180 }} />
                      </h3>
                    )}
                  {domain_name() === false &&
                    window.location.hostname.match("agent.skyfalltech") && (
                      <h3 style={{ "text-align": "center" }}>
                        <img
                          src={Skyfall}
                          alt="Text"
                          style={{ maxWidth: 180 }}
                        />
                      </h3>
                    )}
                  <div className="loginborder">
                    <h3 className="mt-1 heading">Login</h3>

                    <div className="line"></div>

                    <Form
                      onSubmit={handleSubmit}
                      className={isLoading ? "form-disabled" : ""}
                    >
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>UserEmail</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="yours@example.com"
                          value={email}
                          onChange={(e) => handleEmailChange(e.target.value)}
                        />
                        <div className="error-message_">{emailError}</div>
                      </Form.Group>

                      <Form.Group
                        className="mb-5"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <div className="password-input-container">
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div
                            className="password-icon"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <MdVisibility />
                            ) : (
                              <MdVisibilityOff />
                            )}
                          </div>
                        </div>
                      </Form.Group>
                      <Button
                        disabled={
                          isLoading || !email || emailError || !password
                        }
                        variant="primary"
                        type="submit"
                        className="mb-3"
                      >
                        {isLoading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="ms-2">Loading...</span>
                          </>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Form>

                    {path !== "/sandbox/" && (
                      <p
                        className={`${isLoading ? "form-disabled forgot" : "forgot"}`}
                        style={{ cursor: !isLoading ? "default" : "pointer" }}
                        onClick={() => navigate("/forgot")
                        }
                      >
                        Forgot Password?
                      </p>
                    )}
                  </div>
                </div>
                {domain_name() && (
                  <p className="copy">@ 2021 doocti, All rights Reserved</p>
                )}
              </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.login.loggedIn,
    token: state.login.token
  }
}
const mapDispatchToProps = dispatch => {
  return {
    set_login: (type) => dispatch(setLogin(type)),
    set_campaign: (type) => dispatch(setCampaign(type)),
    set_user: (type) => dispatch(setUser(type)),
    set_did: (type) => dispatch(SetDid(type))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)