import React, { useState, useEffect } from 'react'
import { Row, Col, Card, Modal, Table, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import { Bar, Line } from 'react-chartjs-2';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import EventEmitter from '../utils/EventEmitter'
import { useDispatch } from 'react-redux'
import { agentdailywork, getcalllog, inboundOutbound, agentworkhour, callreports, wa_call_reports, getdispocount, postdispo } from "../redux/actions/Dashboard"
import { useNavigate } from 'react-router'
import Select from 'react-select'
import Format from '../utils/format-text'
import breakicon from '../assets/images/timers/break.svg'
import loginimg from '../assets/images/timers/login.svg'
import talktime from '../assets/images/timers/takltime.svg'
import acwimg from '../assets/images/acwtime.svg'
import handletime from '../assets/images/timers/handletime.svg'
import api from "../utils/axios-util";
import { Encode } from '../utils/EncodeDecode';
import { TicketStatus } from '../redux/actions/TicketAction';
function Dashboard(props) {
  let agent = localStorage.getItem('user')
  let selected_campaign = localStorage.getItem('selectedCampaign')
  let Dispatch = useDispatch()
  let navigate = useNavigate()
  const [inboundOutboundPercent, setinboundOutboundPercent] = useState({
    "outbound_success": 0,
    "outbound_failure": 0,
    "inbound_success": 0,
    "dropped": 0,
  })
  const [agentActivitytime, setagentActivitytime] = useState({
    "login_time": "No Data",
    "break_time": "No Data",
    "talk_time": "No Data",
    "handle_time": "No Data",
    "acw_time": "No Data"
  })
  const [dailtwork, setdailtwork] = useState({ callLog: 0, customForm: 0, followUp: 0 })
  const [calllog, setcalllog] = useState([])
  const [dispo, setdispo] = useState([])
  const [dispoticket, setdispoticket] = useState([])
  const [dispostr, setdispostr] = useState("")
  const [Selecteddispo, setSelecteddispo] = useState([])
  const [Selecteddispoticket, setSelecteddispoticket] = useState([])
  const [dispoapidataticket, setdispoapidataticket] = useState([{ key: "No Dispo ", value: "00" }, { key: "No Dispo ", value: "00" }, { key: "No Dispo ", value: "00" }, { key: "No Dispo ", value: "00" }])
  const [dispoapidata, setdispoapidata] = useState([{ key: "No Dispo ", value: "00" }, { key: "No Dispo ", value: "00" }, { key: "No Dispo ", value: "00" }, { key: "No Dispo ", value: "00" }])
  const [Selecteddaterange, setSelecteddaterange] = useState({ label: "This Week", value: "thisweek" })
  const [daterange, setdaterange] = useState([{ label: "This Week", value: "thisweek" }, { label: "Last Week", value: "lastweek" }, { label: "This Month", value: "thismonth" }, { label: "Last Six Month", value: "sixmonth" }, { label: "Last One Year", value: "oneyear" }])

  const [show, setshow] = useState(false)
  const [title, settitle] = useState(null)
  const [wa_call, setwa_call] = useState({
    labels: [],
    datasets: [],
  })
  const [dispoCountData, setDispoCountData] = useState({
    labels: [],
    datasets: [],
  })

  const [showDispoCount, setshowDispoCount] = useState(1)
  const [options, setoptions] = useState({
    plugins: {
      legend: {
        display: false,
      }
    },
    scales: {
      y: {
        display: true,
        grid: {
          drawBorder: false,
          display: true,
          drawTicks: false,
          zeroLineColor: 'rgba(90, 113, 208, 0)',
        },
        ticks: {
          beginAtZero: true,
          stepSize: 10,
          display: true,
          padding: 10,
        }
      },
      x: {
        display: true,
        position: 'bottom',
        grid: {
          drawBorder: false,
          display: false,
          drawTicks: false,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 10,
          fontColor: "#3a3a43",
          padding: 10,
        }
      },
    },

    elements: {
      point: {
        radius: 1
      },
      line: {
        tension: 0
      }
    },

  })
  const [callreportdata, setcallreportdata] = useState({
    labels: [],
    datasets: [],
  })
  const [optionsx, setoptionsx] = useState({
    plugins: {
      legend: {
        display: false,
        position: 'top',
        align: 'end',
        labels: {
          boxWidth: 10,
          pointStyle: 'circle'
        }
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false
        }

      },
      y: {
        grid: {
          display: false
        }
      }
    }
  })

  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  useEffect(() => {
    dailywork()
    // daily_work()
    dispocount('lead')
    dispocount('ticket')
    inboundOutboundRatio()
    agentworkhours()
    callreport()
    wa_call_report(Selecteddaterange.value)
    let dispo_ = JSON.parse(localStorage.getItem("dispo"))
    let arr = [{
      label: 'New',
      value: 'new'
    }]
    for (let i = 0; i < dispo_.length; i++) {
      let obj = {
        label: dispo_[i],
        value: dispo_[i]
      }
      arr.push(obj)
    }
    setdispo(arr)
     
       Dispatch(TicketStatus()).then((res)=>{
       if(res.data.statusCode === 200){
        let arr1 = []
        res.data.data.forEach((item) => {
        const capitalizedLabel = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
        const capitalizedValue =  item.name.slice(0).toLowerCase()
        arr1.push({ label: capitalizedLabel, value: capitalizedValue })
      })
      setdispoticket(arr1)
      }
    })
    
  }, [])
  function dispocount(module) {
    let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"))
    Dispatch(getdispocount({ user: agent, template_name: campaigninfo.template_name, module: module })).then((response) => {

      if (module === "lead") {
        setSelecteddispo(response.data.dispo_arr)
        setdispoapidata(response.data.data)
      } else {
        setSelecteddispoticket(response.data.dispo_arr)
        setdispoapidataticket(response.data.data)
      }

      // setSelecteddispoticket(response.data.dispo_arr)
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'dashboard Api',
      })
    })
  }
  function refresh() {
    dailywork()
    inboundOutboundRatio()
    agentworkhours()
    callreport()
    dispocount('lead')
    dispocount('ticket')
    wa_call_report(Selecteddaterange.value)
  }
  useEffect(() => {

    wa_call_report(Selecteddaterange.value)
  }, [showDispoCount])
  function dailywork() {
    let query = {
      agent,
      campaign:selected_campaign
    }
    Dispatch(agentdailywork(query)).then((response) => {
      if (response.status === 200) {
        setdailtwork(response.data.data)
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'dashboard Api',
      })
    })
  }

  function wa_call_report(type) {

    let query = { agent: agent, chartView: showDispoCount, type: type, wa_permit: false }

    if (showDispoCount == 1) {
      if (AppPermission.wa_view) {
        // wa_permit = true
        query.wa_permit = true
      }
    } query.wa_permit = true
    if (showDispoCount == 2) {
      let dispo_status = JSON.parse(localStorage.getItem('dispo')).toString()
      query.dispo_status = dispo_status
    }
    Dispatch(wa_call_reports(query)).then((response) => {

      if (showDispoCount == 1) {
        if (response.status === 200) {
          setwa_call(response.data.data)

        }
      } else if (showDispoCount == 2) {

        if (response.status === 200) {

          setDispoCountData(response.data.data)


        }

      }

    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'dashboard Api',
      })
    })
  }
  function callreport() {
    Dispatch(callreports(agent)).then((response) => {
      if (response.status == 200) {
        setcallreportdata(response.data.data)
      }

    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'dashboard Api',
      })
    })
  }
  function agentworkhours() {

    Dispatch(agentworkhour(agent)).then((response) => {
      if (response.status === 200) {
        setagentActivitytime(response.data.data)
      }

    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'dashboard Api',
      })
    })
  }
  function inboundOutboundRatio() {

    Dispatch(inboundOutbound(agent)).then((response) => {
      if (response.status === 200) {
        setinboundOutboundPercent(response.data.data)
      }

    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'dashboard Api',
      })
    })
  }
  let openCompletedCalls = (title) => {
    setshow(true)
    settitle(title)
    let query = {
      agent,
      campaign:selected_campaign
    }
    Dispatch(getcalllog(query, "call")).then((response) => {
      if (response.status === 200) {
        setcalllog(response.data.data)
      }

    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'dashboard Api',
      })
    })

  }
  let openCompletedMeeting = (title) => {
    setshow(true)
    settitle(title)
    let query = {
      agent,
      campaign:selected_campaign
    }
    Dispatch(getcalllog(query, "meeting")).then((response) => {
      if (response.status === 200) {
        setcalllog(response.data.data)
      }

    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'dashboard Api',
      })
    })

  }
  let handleClose = () => {
    setshow(false)
    setcalllog([])
  }
  const onSelectionChange = (e, value, module) => {
    if (value === "dispo" && e.length < 5) {
      let dispovalues = []
      for (let i = 0; i < e.length; i++) {
        const element = e[i];
        dispovalues.push(element.value)
      }
      let payload = {
        "user": agent,
        "template_name": JSON.parse(localStorage.getItem("selectedCampaign_data")).template_name,
        "dispo": dispovalues.toString(),
        "module": module
      }

      Dispatch(postdispo(payload)).then((response) => {
        if (response.status === 200) {
          dispocount('lead')
          dispocount('ticket')
        }

      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'dashboard Api',
        })
      })

      if (module === "lead") {
        setSelecteddispo(e)
      } else {
        setSelecteddispoticket(e)
      }


    } else if (value === "overview") {
      setSelecteddaterange(e)
      wa_call_report(e.value)
    }

  }
  const redirecttoMeeting = (phone, id) => {
    navigate(`/meeting/${Encode(phone)}/${id}`)
  }
  const openleads = () => {
    navigate(`/leads`)
  }
  return (
    <div className="page-main-container page-main-container-mobile">

      <div className='scroll-lft' md={8} >
        <div className='dashboard-frame'>
          <div className='dashboard-frame-top'>
            <Row>
              <div className='dashboard-route-header-flex '>
                <div className='dashboard-route-header ' style={{ cursor: 'default'}}> Dashboard</div>
                <div className='dashboard-route-header-rht '>
                  <span class="material-icons custom-cursor" onClick={refresh}>
                    refresh
                  </span>
                </div>

              </div>

            </Row>
          </div>
          <div className='dashboard-frame-bottom'>

            <Row className="mt-3 mb-3">
              <Col xs={12} sm={6} md={3} className='dashboard-frame-bottom-bar' >
                <Card className="dash" >
                  <Card.Body>
                    <div className='timer_flx'>
                      <img
                        className="timeline-badge-image logincls"
                        src={loginimg}
                        alt="test"
                      />
                      <p className='timer_header'>Login Time</p>
                    </div>
                    <div className='loginworkhours' style={{ textAlign: 'center',cursor: 'default' }}>{agentActivitytime.login_time}</div>

                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3} className='dashboard-frame-bottom-bar'>
                <Card className="dash"
                >
                  <Card.Body >
                    <div className='timer_flx'>
                      <img
                        className="timeline-badge-image talktimecls"
                        src={talktime}
                        alt="test"
                      />
                      <p className='timer_header'>Talk Time</p>

                    </div>
                    <div className='talkworkhours' style={{ textAlign: 'center',cursor: 'default' }}>{agentActivitytime.talk_time}</div>

                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3} className='dashboard-frame-bottom-bar'>
                <Card className="dash"

                >
                  <Card.Body >
                    <div className='timer_flx'>
                      <img
                        className="timeline-badge-image breakcls"
                        src={breakicon}
                        alt="test"
                      />
                      <p className='timer_header' >Break Time</p>
                    </div>

                    <div className='breakworkhours' style={{ textAlign: 'center',cursor: 'default' }}>{agentActivitytime.break_time}</div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3} className='dashboard-frame-bottom-bar'>
                <Card className="dash"

                >
                  <Card.Body >
                    <div className='timer_flx'>
                      <img
                        className="timeline-badge-image handlecls"
                        src={handletime}
                        alt="test"
                      />
                      <p className='timer_header' >Handle Time</p>
                    </div>

                    <div className='handleworkhours' style={{ textAlign: 'center' ,cursor: 'default'}}>{agentActivitytime.handle_time}</div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3} className='dashboard-frame-bottom-bar'>
                <Card className="dash" >
                  <Card.Body >
                    <div className='timer_flx'>
                      <img
                        className="timeline-badge-image acwcls"
                        src={acwimg}
                        alt="test"
                      />
                      <p className='timer_header' >Acw Time</p>
                    </div>

                    <div className='acwworkhours' style={{ textAlign: 'center' ,cursor: 'default'}}>{agentActivitytime.acw_time}</div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {AppPermission.lead_view && <Card className="p-4">
              <div className="d-flex  align-items-center justify-content-between">
                <Card.Title className="title_over">Lead Disposition</Card.Title>
                <div className="d-flex align-items-center justify-content-end">

                  <Select
                    isMulti={true}
                    isDisabled={false}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    menuShouldScrollIntoView={true}
                    menuPlacement={`auto`}
                    onChange={(e) => onSelectionChange(e, "dispo", "lead")}
                    value={Selecteddispo}
                    options={dispo}
                    isSearchable={true}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={'Select Disposition'}
                  />
                </div>


              </div>
              <Row className="mt-3">
                {dispoapidata.length > 0 && <>
                  {dispoapidata.map((data, i) => {
                    return (
                      <>
                        {data.key !== "No Dispo " && <Col md={3} key={i}>
                          <Card className="dash" >
                            <Card.Body >
                              <div className='timer_flx'style={{ cursor: 'default'}}>
                                <div>
                                  <div className={i === 0 ? "timeline-badge-image logincls loginworkhours" : i === 1 ? "timeline-badge-image talkworkhours talktimecls" : i === 2 ? "timeline-badge-image breakworkhours breakcls" : "timeline-badge-image handleworkhours handlecls"}>{data.value}</div>

                                </div>

                                <p className='timer_header'>{Format(data.key)}</p>
                              </div>

                            </Card.Body>
                          </Card>
                        </Col>}
                      </>

                    )
                  })}
                </>}
                {Selecteddispo.length === 0 && <>
                  <div className='nodata_'>
                    No Disposition Selected
                  </div>
                </>

                }




              </Row>
            </Card>}
            {AppPermission.ticket_view && <Card className="p-4">
              <div className="d-flex align-items-center justify-content-between">
                <Card.Title className="title_over">Ticket Status</Card.Title>
                <div className="d-flex align-items-center justify-content-end">

                  <Select
                    isMulti={true}
                    isDisabled={false}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    menuShouldScrollIntoView={true}
                    menuPlacement={`auto`}
                    onChange={(e) => onSelectionChange(e, "dispo", "ticket")}
                    value={Selecteddispoticket}
                    options={dispoticket}
                    isSearchable={true}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={'Select Status'}
                  />
                </div>


              </div>
              <Row className="mt-3">
                {dispoapidataticket.length > 0 && <>
                  {dispoapidataticket.map((data, i) => {
                    return (
                      <>
                        {data.key !== "No Dispo " && <Col md={3} key={i}>
                          <Card className="dash" >
                            <Card.Body >
                              <div className='timer_flx'style={{cursor: 'default'}}>
                                <div>
                                  <div className={i === 0 ? "timeline-badge-image logincls loginworkhours" : i === 1 ? "timeline-badge-image talkworkhours talktimecls" : i === 2 ? "timeline-badge-image breakworkhours breakcls" : "timeline-badge-image handleworkhours handlecls"}>{data.value}</div>

                                </div>

                                <p className='timer_header'>{Format(data.key)}</p>
                              </div>

                            </Card.Body>
                          </Card>
                        </Col>}
                      </>

                    )
                  })}
                </>}
                {Selecteddispoticket.length === 0 && <>
                  <div className='nodata_'>
                    No Status Selected
                  </div>
                </>

                }
              </Row>
            </Card>}
            <Row className="mt-3 mb-3">
              <Col md={12}>
                <Card className="p-4">
                  <div className="d-flex align-items-center dashboard-mobile justify-content-between">
                    <Card.Title className="title_over">Overview</Card.Title>
                    <div className="d-flex align-items-center dashboard-mobile justify-content-end">
                      <div id="visit-sale-chart-legend" className="legend-top-right float-right"><ul className="legend-horizontal">{AppPermission.wa_view && <li><span className="legend-dots bg-whatsapp"></span>WhatsApp</li>}<li><span className="legend-dots bg-call"></span>Calls</li></ul></div>
                      <Select
                        isMulti={false}
                        isDisabled={false}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuShouldScrollIntoView={true}
                        menuPlacement={`auto`}
                        onChange={(e) => onSelectionChange(e, "overview")}
                        value={Selecteddaterange}
                        options={daterange}

                        isSearchable={true}
                        placeholder={'Select Range'}
                      />
                    </div>
                    <div className='viewChart'>
                      <div className={(showDispoCount == 1) ? "viewChartlft selectedValue" : "viewChartlft"} onClick={() => setshowDispoCount(1)}>Calls</div>
                      <div className={(showDispoCount == 2) ? "viewChartrgt selectedValue" : "viewChartrgt"} onClick={() => setshowDispoCount(2)}>Dispo</div>
                    </div>

                  </div>
                  {showDispoCount == 1 && <Line
                    data={wa_call}
                    options={options} />}
                  {showDispoCount == 2 && <Line
                    data={dispoCountData}
                    options={options} />}

                </Card>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={4}>
                <Card className="dash" onClick={() => openCompletedCalls('Completed Calls')}>
                  <Card.Body style={{ cursor: 'pointer' }}>
                    <span className="material-icons complete">
                      check
                    </span>
                    <p>Completed Calls</p>
                    <h1>{dailtwork.callLog}</h1>

                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="dash"
                  onClick={() => openCompletedMeeting('Scheduled Calls')}
                >
                  <Card.Body style={{ cursor: 'pointer' }}>
                    <span className="material-icons schedule">
                      phone
                    </span>
                    <p>Meeting Calls</p>
                    <h1>{dailtwork.followUp}</h1>

                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="dash"
                  onClick={() => openleads()}
                >
                  <Card.Body style={{ cursor: 'pointer' }}>
                    <span className="material-icons queue">
                      watch_later
                    </span>
                    <p>Assigned Leads</p>
                    <h1>{dailtwork.customForm}</h1>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={8}>
                <Card className="p-4">

                  <div className="d-flex align-items-center dashboard-mobile justify-content-between">
                    <Card.Title className="title_over">Call Report</Card.Title>
                    <div className="d-flex align-items-center dashboard-mobile justify-content-end">
                      <div id="visit-sale-chart-legend" className="legend-top-right float-right"><ul className="legend-horizontal"><li><span className="legend-dots bg-call"></span>Total</li><li><span className="legend-dots bg-whatsapp"></span>Answered</li><li><span className="legend-dots bg-fail"></span>UnAnswered</li></ul></div>

                    </div>
                  </div>
                  <Bar data={callreportdata} options={optionsx} />
                </Card>
              </Col>
              <Col md={4}>
                {AppPermission.calllog_inbound && <Card className="p-4 mb-3">
                  <Card.Title className="title_over">Today Inbound Close Rate</Card.Title>
                  <div className="row d-flex align-items-center">
                    <div className="col-md-6 circle_top">

                      <CircularProgressbarWithChildren className="progress-order"
                        value={70}>
                        <div className="circle">
                          <h3>{inboundOutboundPercent.inbound_success}%</h3>
                          <p>Success</p>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                    <div className="col-md-6 circle_top">
                      <CircularProgressbarWithChildren className="progress-order"
                        value={70}>
                        <div className="circle">
                          <h3>{inboundOutboundPercent.dropped}%</h3>
                          <p>Drop</p>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </Card>}
                {AppPermission.calllog_outbound && <Card className="p-4 ">
                  <Card.Title className="title_over">Today Outbound Close Rate</Card.Title>
                  <div className=" row d-flex align-items-center">
                    <div className="col-md-6 circle_top red">
                      <CircularProgressbarWithChildren className="progress-order"
                        value={70}>
                        <div className="circle">
                          <h3>{inboundOutboundPercent.outbound_success}%</h3>
                          <p>Success</p>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                    <div className="red col-md-6 circle_top">

                      <CircularProgressbarWithChildren className="progress-order red"
                        value={70}>
                        <div className="circle">
                          <h3>{inboundOutboundPercent.outbound_failure}%</h3>
                          <p>Fail</p>
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </Card>}
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <Modal show={show} size="lg" onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div style={{ height: "400px", "overflow": "auto" }}>
            <Table striped bordered hover>
              {title === "Completed Calls" && calllog.length > 0 &&
                <>
                  <thead>
                    <tr>
                      <th>Sno</th>
                      <th>Phone Number</th>
                      <th>Call Status</th>
                      <th>Call Time</th>
                      <th>Call Duration</th>
                    </tr>
                  </thead>

                  <tbody>
                    {calllog.map((value, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{Format(value.phone_number, { maskbool: true })}</td>
                          <td>{Format(value.dispo_status)}</td>
                          <td>{value.calldate}</td>
                          <td>{value.duration} Sec</td>
                        </tr>
                      )
                    })

                    }
                  </tbody>


                </>
              }
              {title === "Scheduled Calls" && calllog.length > 0 &&
                <>
                  <thead>
                    <tr>
                      <th>Sno</th>
                      <th>Phone Number</th>
                      <th>Campaign</th>
                      <th>Meeting Time</th>

                    </tr>
                  </thead>

                  <tbody>
                    {calllog.map((value, i) => {
                      return (
                        <tr onClick={() => { redirecttoMeeting(value.phone_number, value.id) }} key={i} className='enablebutton'>
                          <td>{i + 1}</td>
                          <td>{Format(value.phone_number, { maskbool: true })}</td>
                          <td>{Format(value.campaign)}</td>
                          <td>{value.calldate}</td>

                        </tr>
                      )
                    })

                    }
                  </tbody>


                </>
              }


              {calllog.length === 0 &&
                <div className='nodata'>No Data</div>
              }

            </Table>
          </div>
        </Modal.Body>

      </Modal>
    </div >
  )

}

export default Dashboard