import React, { useEffect, useMemo, useState } from "react";
import imcoming from "../assets/images/person.svg";
import lines from "../assets/images/call_lines.png";
import { connect, useSelector, useDispatch } from "react-redux";
import { fetchcallaction } from "../redux/Calls/CallAction";
import contact_png from "../assets/images/contact.png";
import TransferAgentCall from "./transferAgentCall";
import TransferOthersCall from "./transgerOthersCall";
import TransferQueueCall from "./transferQueueCall";
import TransferCentersCall from "./transferCenterCall";
import Conference from "./conference";
import { CustomformData, } from '../redux/actions/Customform'
import { ContactFetch } from '../redux/actions/ContactActions'
import { tempData } from '../redux/background/BackgroundAction'
import Dialpad from '../components/CallDialPad'
import { Divider } from "@material-ui/core";
import Format from "../utils/format-text";
function Oncall(props) {

  let Dispatch = useDispatch()
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  let callcontroll = JSON.parse(localStorage.getItem("callcontroll"))
  let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"))
  const [forward, setForward] = useState(false);
  const [pause, setPause] = useState(false);
  let enable_callrecord = JSON.parse(localStorage.getItem("call_recording"))
  const [dialpad, setDialpad] = useState(false);
  const [mic, setMic] = useState(false);
  const [conf, setConf] = useState(false);
  const [transfer, setTransfer] = useState(0);
  const [conferencehide, setconferencehide] = useState(true);
  const [transferStatus, settransferStatus] = useState(true);
  const [addForwardperson, setaddForwardperson] = useState(false);
  const [name, setname] = useState()
  const [callstatus, setcallstatus] = useState("00:00")
  const [seconds, setSecond] = useState(parseInt(localStorage.getItem("callcount")));
  const [transferhide, settransferhide] = useState(false)
  const [Dialpadview, setDialpadview] = useState(false)
  const [callrecord, setCallRecord] = useState(true);
  let stateValue = useSelector(state => state);
  useEffect(() => {

    if (AppPermission.agent_transfer) {
      setTransfer(1)
    } else if (AppPermission.queue_transfer) {
      setTransfer(2)
    } else if (AppPermission.other_transfer) {
      setTransfer(3)
    } else if (AppPermission.did_tranfer) {
      setTransfer(4)
    }
    if (callcontroll.hold) {
      setPause(true)
    }
    if (callcontroll.dialpad) {
      setDialpadview(true)
    }
    if (callcontroll.mute) {
      setMic(true)
    }
    if (callcontroll.conf) {
      setConf(true)
      settransferhide(false)
      setconferencehide(true)
    }
    if (callcontroll.transfer) {
      setForward(true)
      settransferhide(true)
      setconferencehide(false)
    }
    setDialpadview(false)
    return ()=> {
      localStorage.setItem("callcontroll", JSON.stringify({
        mute: false,
        hold: false,
        transfer: false,
        conf: false,
        dialpad: false
      }))
    }
    
  }, [])


  useEffect(async () => {
    if (stateValue.background.socket_message.evt === "RINGING") {
      localStorage.setItem("callcount", "0")
      setSecond(parseInt(localStorage.getItem("callcount")))
      let statusCall = stateValue.background.socket_message.calltype === 'xfer' ? 'transferd call':'Outgoing Call'
      setcallstatus(statusCall)
    }
    else {
      localStorage.setItem("callcount", "0")
      setcallstatus("00:00")

    }


    if (stateValue.background.socket_message.phonenumber !== undefined) {
    await  Dispatch(
        ContactFetch(
          `/contact/fetchsearch?number=${stateValue.background.socket_message.phonenumber}`,
        ),
      ).then((res) => {
        const s = res?.data?.data
        if (s?.length > 0) {
          Dispatch(tempData({type:"contact",data:s[0]}))
          setname(res.data.data[0].name)
        }
        else {
          Dispatch(tempData({type:"",data:{}}))
          let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
          let payload = {
            template_name: campaigninfo.template_name,
            type: campaigninfo.type,
            phone_number: stateValue.background.socket_message.phonenumber,
            id: ''
          }
          Dispatch(CustomformData(payload)).then((res) => {
            if (res.status != 204) {
              setname(res.data.data[0].others.first_name)

            } else {
              setname('Unknown Number')
            }


          })
        }
      })
      // if (stateValue.background.oncall !== true) {
      //   setname(localStorage.getItem("dialpadname"))
      // } else {
      //   setname(localStorage.getItem("dialpadname"))
      // }
    }
  }, [stateValue.background.socket_message.evt])

  useEffect(
    () => {

      if (props.checktransfer === true) {
        if (callcontroll.transfer === false) {
          setconferencehide(true);
        }

      } else if (props.checktransfer === false) {
        setconferencehide(false);
      }
    },
    [props.checktransfer]
  );

  useEffect(() => {
    localStorage.setItem("callcount", seconds)
  }, [seconds])

  useEffect(() => {

    if (props.call_record_res.length > 0) {
      setCallRecord(props.call_record_res[0].state)
    }
  }, [props.call_record_res])


  var interval = null;
  useEffect(() => {
    if (stateValue.background.socket_message.evt === "ANSWERED") {
      interval = setInterval(() => {
        setSecond(seconds => seconds + 1);
      }, 1000)


      //  props.callRecord(!callrecord) 

    } else if (stateValue.background.socket_message.evt === "DISPO") {
      setSecond(0);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [stateValue.background.socket_message.evt])

  useEffect(() => {
    if (props.checktransfer === true) {
      settransferhide(true)
    } else if (props.checktransfer === false) {
      settransferhide(false)
    }
    //  else {
    //   if (callcontroll.conf) {
    //     setConf(true)
    //     settransferhide(false)
    //   }
    //   if (callcontroll.transfer) {
    //     setForward(true)
    //     settransferhide(true)

    //   }
    // }

  }, [props.checktransfer])
  const phoneForward = status => {
    console.log(status, "uuuuuuuuuuuuuuuuuuu")
    if (props.checktransfer === false) {
      setForward(!status);
      setConf(false);
      setconferencehide(false);
      setDialpad(false);
      props.callAction("on_call");
    } else {
      setForward(!status);
      setconferencehide(!conferencehide);
      setConf(false);
      setDialpad(false);
      props.callAction("on_call");
    }
    settransferStatus(forward);
    callcontroll.transfer = !forward
    localStorage.setItem("callcontroll", JSON.stringify(callcontroll))
  };
  const phonePause = status => {

    setPause(!status);

    if (!pause) {
      props.Oncall_call_Action("hold");
    } else {
      props.Oncall_call_Action("unhold");
    }
  };
  const phoneDialpad = status => {
    setDialpad(false);
    setDialpadview(!status);
    callcontroll.dialpad = !status
    localStorage.setItem("callcontroll", JSON.stringify(callcontroll))
    if (!status === true) {
      // props.callAction("on_dial");
      setDialpad(true);
    }
  };

  const phoneMic = status => {
    setMic(!status);
    if (!mic) {
      props.Oncall_call_Action("mute");
    } else {
      props.Oncall_call_Action("unmute");
    }
  };
  const conference = status => {
    setConf(!conf);
    setForward(false);
    setDialpad(false);
    props.callAction("on_call");
    callcontroll.conf = !conf
    localStorage.setItem("callcontroll", JSON.stringify(callcontroll))
  };

  function callcut() {
    localStorage.setItem("webrtcManual", "false");
    props.callend(true, seconds);

  }
  function socketTransfer(data) {
    props.transferScocket(data);
  }
  function socketconference(data) {
    props.conferenceScocket(data);
  }
  function addforward() {
    setaddForwardperson(!addForwardperson);
  }

  function conf_mute_action(data) {
    props.conferencemute(data);
  }
  function conf_kickout_action(data) {
    props.conferencekick(data);
  }

  function conf_end(data) {
    props.confend(data);
  }

  function back2form() {
    props.back2form();
  }

  const call_recording = data => {

    props.callRecord(data)
    setCallRecord(data);

  };
  function forwardkickout() {

    props.forwardkickout()
  }

  function setTransfercall(data) {
    setTransfer(data)
  }
  function reduceLength(value){
    if (value.length > 15) {
      return value.substring(0, 15) + '...';
    }
    return value;
  }
  useEffect(()=>{

  },[props.transferStatuschange])

  return (
    <>

      <>
        <div className="oncall">
          <div className="wave" />
          <div className="call-animation1">

            <img src={imcoming} className="img-circle" alt="test" style={{ cursor: 'pointer' }} onClick={() => back2form()} />
          </div>
          <h2 className="oncalltxt" style={{ cursor: 'pointer' }} onClick={() => back2form()}>{reduceLength(Format(name, { maskbool: true }))}</h2>
          <div className="oncallnumber" style={{ cursor: 'pointer' }} onClick={() => back2form()}>{Format(stateValue.background.socket_message.phonenumber, { maskbool: true })}</div>
         
          <p className={stateValue.background.socket_message.evt === "RINGING" ? "call_count_txt" : "call_count"}>{!AppPermission.webrtc &&callstatus === "Outgoing Call" ?"Outgoing Call":callstatus === "Outgoing Call" ? callstatus :callstatus === 'transferd call'? 'Transferd Call' : "0" +
            Math.floor(seconds / 3600 % 60) +
            ":" +
            ("0" + Math.floor(seconds / 60 % 60)).slice(-2) +
            ":" +
            ("0" + Math.floor(seconds / 1 % 60)).slice(-2)} </p>


          <div className={ `end_oncall_action ${props.transferStatuschange ? 'disabledbutton' : ''}` }>
            <a href="#auto_scroll_dispo">
              <span className="material-icons red" onClick={callcut}>
                call_end
              </span>
            </a>
          </div>
          {stateValue.background.socket_message.evt ===

            "ANSWERED" &&
            <div className="oncall_action">
              {AppPermission.oncall_dialpad_DTMF && <a
                href="#auto_scroll"
                className={
                  Dialpadview ? "material-icons forward active" : "material-icons"
                }
                onClick={() => phoneDialpad(Dialpadview)}
              >
                dialpad
              </a>}
              {AppPermission.oncall_mute && AppPermission.webrtc &&
                <a
                  href="#auto_scroll"
                  className={
                    mic ? "material-icons forward active" : "material-icons"
                  }
                  onClick={() => phoneMic(mic)}
                >
                  mic_off
                </a>}
              {AppPermission.oncall_hold && AppPermission.webrtc && <a
                href="#auto_scroll"
                className={
                  pause ? "material-icons forward active" : "material-icons"
                }
                onClick={() => phonePause(pause)}
              >
                pause
              </a>}
              {enable_callrecord == 'Y' && <div className="record_div">
                {callrecord && enable_callrecord == 'Y' && <p className="unread oncallrecord " />}

                <div>
                  <a
                    href="#auto_scroll"
                    className={
                      callrecord ? " material-icons forward active" : "material-icons"
                    }
                    // className="material-icons album active"
                    onClick={() => call_recording(!callrecord)}
                  >
                    graphic_eq
                  </a>
                </div>

              </div>}
              {transferhide &&
                conf === false && stateValue.background.conference.length === 0 && AppPermission.view_transfer &&
                <a
                  href="#auto_scroll_forward"
                  className={
                    forward ? "material-icons forward active" : "material-icons"
                  }
                  onClick={() => phoneForward(forward)}
                >
                  phone_forwarded
                </a>}
              {conferencehide && AppPermission.conference_view &&
                <a
                  href="#auto_scroll_conf"
                  className={
                    conf ? "material-icons forward active" : "material-icons"
                  }
                  onClick={() => conference(conf)}
                >
                  people
                </a>}
              {AppPermission.oncall_backtoform && <a
                className={
                  "material-icons"
                }
                onClick={() => back2form()}
              >
                description
              </a>}
            </div>}
          {/* <div className="bottom-wave" /> */}
        </div>
        {forward &&
          <div id="auto_scroll_forward" className="incoming-forward">
            <div className={props.transferStatuschange ? "contact_head disabledbutton" : "contact_head"}>
              <div className="contact_head_txt">
                {AppPermission.agent_transfer && <div
                  onClick={() => setTransfercall(1)}
                  className={
                    transfer === 1
                      ? "contact_head_txt-subdiv active enablebutton"
                      : "contact_head_txt-subdiv enablebutton"
                  }
                >
                  Agents
                </div>}
                {AppPermission.queue_transfer && <div
                  onClick={() => setTransfercall(2)}
                  className={
                    transfer === 2
                      ? "contact_head_txt-subdiv active enablebutton"
                      : "contact_head_txt-subdiv enablebutton"
                  }
                >
                  Queue
                </div>}
                {AppPermission.did_tranfer && <div
                  onClick={() => setTransfercall(4)}
                  className={
                    transfer === 4
                      ? "contact_head_txt-subdiv active enablebutton"
                      : "contact_head_txt-subdiv enablebutton"
                  }
                >
                  Contact
                </div>}
                {AppPermission.other_transfer && <div
                  onClick={() => setTransfercall(3)}
                  className={
                    transfer === 3
                      ? "contact_head_txt-subdiv active enablebutton"
                      : "contact_head_txt-subdiv enablebutton"
                  }
                >
                  Other
                </div>}

              </div>
              <div className="contact_head_close">
                <span
                  className="material-icons enablebutton"
                  onClick={() => phoneForward(forward)}
                >
                  close
                </span>
              </div>
            </div>
            <div className='call-action-controll'>
              {AppPermission.agent_transfer && transfer === 1 && <div>
                <TransferAgentCall
                  transferStatus={transferStatus}
                  agentActionType={"transfer"}
                  agenttransfer={socketTransfer}
                  transfer={transfer}
                  transfercall={props.transferresponse} 
                />
              </div>}
              {AppPermission.other_transfer && transfer === 3 && <div >
                <TransferOthersCall
                  agentActionType={"transfer"}
                  transfercall={props.transferresponse} 
                  othertransfer={socketTransfer}
                />
              </div>}

              {AppPermission.queue_transfer && transfer === 2 &&
                <div>
                  <TransferQueueCall queuetransfer={socketTransfer} transfercall={props.transferresponse}/>
                </div>}
              {AppPermission.did_tranfer && transfer === 4 && <div>
                <TransferCentersCall callcentertransfer={socketTransfer} transfercall={props.transferresponse} />
              </div>}
            </div>
            <div>
              <div
                style={{
                  textAlign: "center",
                  color: "#2977EC",
                  paddingTop: 20,
                  paddingBottom: 10
                }}
              >
                {AppPermission.view_manage_transfer && <span onClick={() => addforward()} className={`${props.transferresponse.length > 0 ? 'enablebutton' : 'disabledbutton ' }`}>Manage Transfer</span>}
              </div>
              {addForwardperson &&
                <div>
                  {props.transferresponse.length > 0 &&
                    <div className="manage_conf">

                      <h3>{Format(props.transferresponse[0].name, { maskbool: true })}</h3>
                      <div>
                        <span onClick={() => forwardkickout()} className="material-icons redend enablebutton">logout</span>
                      </div>
                    </div>
                  }
                </div>}
            </div>
          </div>}

        {conf && AppPermission.conference_view &&
          <div id="auto_scroll_conf" className="incoming-forward">
            <Conference
              conf_end={conf_end}
              conf_kickout_action={conf_kickout_action}
              conf_mute_action={conf_mute_action}
              otherconference={socketconference}
              agentconference={socketconference}
              showconference={conference}
              socketConference={socketTransfer}
              muteconf={props.muteconf}
            />
          </div>}
        {Dialpadview &&
          <Dialpad type={"dtmf"} ></Dialpad>

        }
      </>
    </>
  );
}
const mapDispatchToProps = dispatch => {
  return {
    callAction: type => dispatch(fetchcallaction(type))
  };
};
export default connect(null, mapDispatchToProps)(Oncall);
