import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import '../assets/css/App.css'
import slideImg1 from '../assets/images/home1.png'
import logo from '../assets/images/home.png'
import { useNavigate } from 'react-router'
import { connect, useDispatch } from 'react-redux'
import Select from 'react-select'
import {
  setType,
  setPhone,
  setOptions,
  setPause,
  setQueue, SetDid
} from '../redux/Details/DetailAction'
import { LoginUserinfo } from "../redux/actions/login"
import EventEmitter from '../utils/EventEmitter'
import { AsterGetCampaigns } from '../redux/actions/SelectCRM'
import { AsterGetCampaignsData ,AsterAgentTable} from '../redux/actions/AsterHeader'

function SelectCRM(props) { 
  let dispatch = useDispatch()
  let AppPermissions
  let userInfo
  if (localStorage.getItem('config')) {
    AppPermissions = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
    userInfo = JSON.parse(localStorage.getItem('config')).data.projects[0].userInfo[0]
  }
  let navigate = useNavigate()
  const [value, setValue] = useState('inbuild')
  const [selectedType, setSelectedType] = useState(null)
  const [phone, setPhone] = useState("")
  const [campaign, setcampaign] = useState([])
  const [formValidation,setFormValidation] = useState({
    validation:false,
    message:''
  });

  const re = /^[0-9\b]+$/
  const isErr = '*Numbers Must Be Integer'

  useEffect(() => {
    if(localStorage.getItem('process')){
      navigate('/dashboard')
    }
    localStorage.setItem('selectedQueue', JSON.stringify([]))
    localStorage.setItem('socketConnectCount', 0)
    localStorage.setItem("wasession", "false")

    props.set_pause('loginBreak')

    // props.set_queue([]);

    if (props.campaigns.length > 0) {
      let arr = []
      for (let i = 0; i < props.campaigns.length; i++) {
        let obj = {}
        const element = props.campaigns[i]
        obj['label'] = element
        obj['value'] = element
        arr.push(obj)
      }
      setcampaign(arr)
    }
    userInfo = JSON.parse(localStorage.getItem('config')).data.projects[0].userInfo[0]
    setPhone(userInfo.user_phone_number)
  }, [])

  const selectCRM = (type) => {
    setValue(type)
  }

  const handleSubmit = () => {

    const user = props.user
    const token = props.token
    if(extValidation(phone)){
      return
    }
    let data = {
      agent: user,
      campaign: selectedType
    }

    if(phone === ""){
      EventEmitter.emit('error-message-getter', {
        action: "noNumber",
        module: "login",
      })
      return
    }
    dispatch(AsterGetCampaignsData(selectedType))
      .then((res) => {
        const options = {}
        localStorage.setItem("campaigndetails",JSON.stringify(res.data.data))
        localStorage.setItem("process",res.data.data.process)
        let queue = res.data.data.ingroup.split(',')
        let pausecode = res.data.data.pausecode.split(',')
        let dispo = res.data.data.dispo_statuses.split(',')
        localStorage.setItem('list', JSON.stringify(res.data.data.list))
        localStorage.setItem('call_recording', JSON.stringify(res.data.data.call_recording))
        localStorage.setItem(
          'dial_status',
          JSON.stringify(res.data.data.dial_status.split(',')),
        )
        localStorage.setItem(
          'script_name',
          res.data.data.script_name,
        )
        let masking = res.data.data.masking
        options.pauseCode = pausecode
        options.queues = queue
        options.dispo = dispo
        props.set_options(options)
        if (res.data.statusCode === 200) {
          props.set_type(selectedType)
          props.set_phone(phone)
          if (selectedType != 'Select' && selectedType != null) {
            dispatch(AsterGetCampaigns(selectedType))
              .then((res) => {
                let setcampaign = JSON.parse(localStorage.getItem("selectedCampaign_data"))
                setcampaign.masking = masking
                localStorage.setItem("selectedCampaign_data", JSON.stringify(setcampaign))
                let payload = {
                  avatar_url: "",
                  name: userInfo.user_name,
                  station: phone,
                  user: userInfo.user_email,
                  campaign: selectedType,
                  agent_id: userInfo.user_id
                }
                dispatch(LoginUserinfo(payload))
                  .then(function (response) {
                    if (response.data.status === 200) {
                      localStorage.setItem("extension", phone);
                      localStorage.setItem("did", JSON.stringify(response.data.did_no))
                      props.set_did(response.data.did_no)
                      dispatch(AsterAgentTable(data)).then((res)=>{
                      if(res?.data?.statusCode === 201 ||res?.data?.statusCode === 200){
                      if (selectedType != 'Select' && selectedType != null) {
                        if (AppPermissions.view_Performance) {
                          navigate('/dashboard')
                        } else if (AppPermissions.lead_view) {
                          navigate("/leads")
                        } else if (AppPermissions.ticket_view) {
                          navigate("/tickets")
                        } else if (AppPermissions.view_contacts) {
                          navigate("/contact")
                        } else if (AppPermissions.view_meeting) {
                          navigate("/meeting")
                        } else if (AppPermissions.voicemail_view) {
                          navigate("/voicemail")
                        }
                      }
                    } else{
                      EventEmitter.emit('error-message-getter', {
                        action: 'field',
                        module: "fields can't fetch try again",
                      })
                    }
                  }).catch((err) => {
                    EventEmitter.emit('error-message-getter', {
                      action: 'field',
                      module: "somthing went wrong",
                    })
                  });
                }
                    }).catch((err)=>{ 
                      if (err.response.data.message === "User Limit Exceed") {
                        localStorage.removeItem("campaigndetails");                         
                        localStorage.removeItem("process");                         
                        localStorage.removeItem("list");                         
                        localStorage.removeItem("call_recording");                         
                        localStorage.removeItem("dial_status");                         
                        localStorage.removeItem("script_name"); 
                        EventEmitter.emit('error-message-getter', {
                          action: 'exceed',
                          module: "login",
                        })
                      }
                      else{
                        localStorage.removeItem("campaigndetails");                         
                        localStorage.removeItem("process");                         
                        localStorage.removeItem("list");                         
                        localStorage.removeItem("call_recording");                         
                        localStorage.removeItem("dial_status");                         
                        localStorage.removeItem("script_name"); 
                      EventEmitter.emit('error-message-getter', {
                        action: 'user',
                        module: "login",
                      })
                    }
                      })

              })
              .catch((err) => {
                EventEmitter.emit('error-message-getter', {
                  action: 'fetch',
                  module: 'campaign data',
                })
              })
          }
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'campaign data',

        })
      })

  }

  const extValidation = (value) =>{
    let num=Number(value)
    console.log(typeof num )
    const regex = /^(?!\d{1,2}$|\d{16,})\d{3,15}$/;
    
    if(!regex.test(value)){
      setFormValidation((set)=>({
        ...set,
        validation:true,
        message : "The extention should be between 3 and 15 digits."
      }));
      return true
    }else if( /^0+$/.test(value)){
      setFormValidation((set)=>({
        ...set,
        validation:true,
        message : "The extention number should not be" +" " +value
      }));
      return true
    }else{
      setFormValidation((set)=>({
        ...set,
        validation:false,
        message : ""
      }));
    }
  }
  const changePhone = (e) =>{
    extValidation(e.target.value)
    setPhone(e.target.value)
  }

  return (
    <div
      className="d-flex align-items-center justify-content-center selectcrm-bg"
      style={{ height: '100vh' }}
    >
      <div className="box1">
        <h3 className="title">Select Campaign</h3>
        {/* <div
          className="d-flex align-items-center justify-content-evenly"
          style={{ padding: '30px' }}
        >
          <div
            className={value === 'inbuild' ? 'size active' : 'size'}
            style={{ marginRight: '44px' }}
            onClick={() => selectCRM('inbuild')}
          >
            <img src={slideImg1} alt="Text" />
            <span>Inbuilt</span>
          </div>
          <div
            className={value === 'other' ? 'size active' : 'size'}
            onClick={() => selectCRM('other')}
          >
            <img src={logo} alt="Text1" />
            <span>Others</span>
          </div>
        </div> */}
        <div className=" row " style={{ padding: '0 20px' }}>
          {value === 'inbuild' && (
            <div className="col-md-12 mt-4">
              <Form.Control
                className='mb-3 no-arrows'
                type="number"
                disabled={AppPermissions.edit_extension}
                defaultValue={phone}
                placeholder="Phone Number or Extension"
                onChange={(e) => {
                  changePhone(e)
                }}
              />
            {formValidation.validation&& (
            <p style={{ color: 'red'}}>{formValidation.message}</p>
            )}
              <Select
                isMulti={false}
                isDisabled={false}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                menuShouldScrollIntoView={true}
                menuPlacement={`auto`}
                onChange={(e) => setSelectedType(e.value)}
                options={campaign}
                isSearchable={true}
                placeholder={'Select Campaign'}
              />
            </div>
          )}
          {value === 'other' && (
            <div className=" col-md-12 ">
              <Form.Control
                type="text"
                defaultValue={phone}
                placeholder="Phone Number"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          )}
          {!re.test(phone) && phone !== '' && phone !== null && (
            <p style={{ color: 'red', padding: '15px' }}>{isErr}</p>
          )}
        </div>
        <div style={{ padding: '20px' }}>
          <Button
            variant="primary"
            type="submit"
            className="mb-3"
            disabled={!phone || !selectedType ||formValidation.validation}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    campaigns: state.detail.campaign,
    user: state.detail.user,
    token: state.login.token,
    selectedcampaign: state.detail.selected_campaign,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    set_type: (type) => dispatch(setType(type)),
    set_phone: (type) => dispatch(setPhone(type)),
    set_options: (type) => dispatch(setOptions(type)),
    set_pause: (type) => dispatch(setPause(type)),
    set_queue: (type) => dispatch(setQueue(type)),
    set_did: (type) => dispatch(SetDid(type))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCRM)
