import React, { useState } from 'react'
import { Col, Form, Row, Button, Card } from 'react-bootstrap'
import CallScreen from '../components/CallScreen'
import LeadTable from '../components/Table'
import MeetingTable from '../components/Table'
import Format from '../utils/format-text'
import EventEmitter from '../utils/EventEmitter'
import { useEffect } from 'react'
import moment from 'moment'
import {
  fetch_meetings,
  editMeeting, Meetingtitle,
  create_meeting,
} from '../redux/actions/Meetings'
import { useDispatch, useSelector } from 'react-redux'
import Dialogbox from '../components/Dialogbox'
import Filters from '../components/filter'
import { useNavigate } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Outbound } from '../redux/SocketAction/SocketAction'
import { callmodule } from '../redux/Calls/CallAction'
import { Encode } from '../utils/EncodeDecode'
import { ContactFetch } from '../redux/actions/ContactActions'
import { CustomformData } from '../redux/actions/Customform'
import waicon from '../assets/images/whatsapp.svg'
import { getTemplate } from '../redux/actions/ChatActions'
import { waTemplate } from '../redux/SocketAction/SocketAction'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { AsterAgentTableField, AsterAgentTableUpdate } from '../redux/actions/AsterHeader'
import DynamicField from '../components/DynamicField'
import { adminPermission } from '../utils/localstorage-provider'
function Meeting() {

  const user_id = localStorage.getItem('user')
  let AppPermission
  let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
  const [meeting_title, setmeeting_title] = useState([])
  let [wadata, setwadata] = useState([])
  let [dynamicHead,setDynamicHead] = useState(false)
  const [edit_mode, setedit_mode] =  useState(false)

  const [popupdata, setpopupdata] = useState({
    open: false,
    Type: 'form_bar',
    field: [
      {
        type: 'SelectList',
        label: 'Meeting Title',
        placeholder: 'please select meeting title',
        required: true,
        model: 'name',
        option: [],
        multiple: false,
        value: '',
        readonly: false,
      },
      {
        type: 'TextInput',
        label: 'others',
        placeholder: 'enter Meeting Name',
        required: false,
        model: 'others',
        value: "",
        readonly: false,
        multiple: false,
        fieldtype: 'others',
        model_type: "Text",
        show: false
      }
      ,{
        type: 'SelectList',
        label: 'Meeting Sub Title',
        placeholder: 'please select meeting sub title',
        required: false,
        model: 'meeting_sub_title',
        option: [],
        multiple: false,
        value: '',
        readonly: false,
        show:false
      },
      {
        type: 'TextInput',
        label: 'Phone Number',
        placeholder: 'Phone Number',
        required: true,
        model: 'meetingphoneNumber',
        value: "",
        readonly: false,
        multiple: false,
        fieldtype: 'phone_number',
        model_type: "Number"
      }, {
        type: 'SelectList',
        label: 'Module',
        placeholder: 'Module',
        required: true,
        model: 'module',
        option: [],
        multiple: false,
        value: '',
      },
      {
        type: 'DatetimePicker',
        allow_previousdate:true,
        label: 'Schedule Date',
        placeholder: 'please select',
        required: true,
        model: 'scheduleDate',
        multiple: true,
        value: moment(datetime()).add(16, 'm').toDate(),
      },
      {
        type: 'LongTextInput',
        label: 'Description',
        placeholder: 'enter your comments',
        required: true,
        model: 'comments',
        value: '',
        multiple: false,
      },
    ],

    title: 'Create Meeting',
    Message: 'Created Successfully ',
    Butonstatus: 'Create',
  })
  const [popUpData_, setpopUpData_] = useState({
    open: false,
    Type: 'form_bar',
    field: [
      {
        type: 'SelectList',
        label: 'Whatsapp Template',
        placeholder: ' select message',
        required: true,
        model: 'whatsapp_template',
        option: [],
        multiple: false,
        value: '',
      },
    ],
    title: 'Whatsapp Template',
    Message: 'Selected Successfully',
    Butonstatus: 'Send',
  })
  let [loader, setloader] = useState(false)
  let wasession = localStorage.getItem("wasession")
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  let stateValue = useSelector((state) => state)
  const [lead_id, setLead_id] = useState('')
  const [shedulealart, setshedulealart] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [searchdata, setSearch] = useState('')
  let dispatch = useDispatch()
  const [meetingDetail, setMeetingDetail] = useState([])
  const [editId, setEditId] = useState('')
  const [skipdata, setskipdata] = useState({ skip: 0, take: 20 })
  const [count, setcount] = useState(0)
  const [infinitScroll, setinfinitScroll] = useState({ limit: 7, more: true })
  const [filterdata, setfilterdata] = useState({})
  let selected_campaign = localStorage.getItem('selectedCampaign')
  let navigate = useNavigate()
  const [popUpData, setpopUpData] = useState({
    open: false,
    Type: 'form_bar',
    field:
      [
        {
          type: 'SelectList',
          label: 'Meeting Title',
          placeholder: 'please select meeting title',
          required: true,
          model: 'name',
          option: [],
          multiple: false,
          value: '',
          readonly: false,
        },
        {
          type: 'TextInput',
          label: 'others',
          placeholder: 'enter Meeting Name',
          required: false,
          model: 'others',
          value: "",
          readonly: false,
          multiple: false,
          fieldtype: 'others',
          model_type: "Text",
          show: false
        },{
          type: 'SelectList',
          label: 'Meeting Sub Title',
          placeholder: 'please select meeting sub title',
          required: false,
          model: 'meeting_sub_title',
          option: [],
          multiple: false,
          value: '',
          readonly: false,
          show:true
        }
        ,
        {
          type: 'TextInput',
          label: 'Phone Number',
          placeholder: 'Phone Number',
          required: true,
          model: 'meetingphoneNumber',
          value: "",
          readonly: true,
          multiple: false,
          fieldtype: 'phone_number',
          model_type: "Number"
        },
        {
          type: 'DatetimePicker',
          allow_previousdate:true,
          label: 'Schedule Date',
          placeholder: 'please select',
          required: true,
          model: 'scheduleDate',
          multiple: true,
          value: '',
        },
        {
          type: 'LongTextInput',
          label: 'Description',
          placeholder: 'enter your comments',
          required: true,
          model: 'comments',
          value: '',
          multiple: false,
        },
        {
          label: 'Meeting ID',
          required: false,
          model: 'meetingID',
          option: [],
          multiple: false,
          value: '',
          readonly: false,
        },
      ],
    title: 'Update Schedule Date ',
    Message: 'Updated Successfully',
    Butonstatus: 'Save',
  })

  const [popUpDatatoCreate, setPopUpDatatoCreate] = useState({
    open: false,
    Type: 'form_bar',
    field: [
      {
        type: 'TextInput',
        label: 'Phone Number',
        placeholder: 'enter your phoneNumber',
        required: true,
        model: 'phoneNumber',
        value: phoneNumber != '' ? phoneNumber : '',
        multiple: false,
      },
      {
        type: 'DatetimePicker',
        allow_previousdate:true,
        label: 'Schedule Date ',
        placeholder: 'please select',
        required: true,
        model: 'scheduleDate',
        multiple: false,
        value: datetime(),
      },
      {
        type: 'LongTextInput',
        label: 'Comments',
        placeholder: 'enter your comments',
        required: true,
        model: 'subject',
        value: '',
        multiple: false,
      },
    ],
    title: 'Create Meeting (Schedule Date Must 15Min After)',
    Message: 'Created Successfully',
    Butonstatus: 'Create',
  })

  const [popUpData1, setpopUpData1] = useState({
    open: false,
    Type: 'Notification_bar',
    field: [],
    title: 'Update Schedule Date',
    Message: 'Sheduled date updated Successfully',
    Butonstatus: 'OK',
  })



  const [header, setheader] = useState([
    { Header: () => (
      <div >
        <span>Action</span>
      </div>
    ),
    accessor: 'action',
    isrender: false,

      Cell: ({ row }) => {
        return (
          <div className="lead_action">
            {adminPermission('leadmanagement:meetings:view')&&AppPermission.view_meeting && <span
              onClick={() =>
                MeetingView(row, 'MeetingView', row.original.meetingID)
              }
              className="material-icons"
              style={{ color: '#3e98c7' }}
            >
              info
            </span>}
            {adminPermission('leadmanagement:meetings:edit')&&AppPermission.meeting_reschedule && <div
              className="material-icons"
              onClick={() =>
                row.original.status !== 'completed' &&

                dialogopen(
                  true,
                  row.original.meetingID,
                  row.original.all,
                )
              }
              style={{ color: row.original.status.toLowerCase() === 'completed' ? '#ccc' : '#FF9900' ,
                       cursor:row.original.status.toLowerCase()  === 'completed' ? 'not-allowed' : 'pointer',
                       pointerEvents: row.original.status.toLowerCase() === 'completed' ? 'none' : 'auto',
                       opacity: row.original.status.toLowerCase() === 'completed' ? 1 : 1,
                    }}
            >
              history
            </div>
            }

            {AppPermission.view_meeting && <span
              onClick={() =>
                clickcall(row)
              }
              className={stateValue.background.oncall == false && wasession === "false" ? "material-icons" : "material-icons disabledbutton"}
              style={{ color: row.original.status.toLowerCase() === 'completed' ? '#ccc' : '#32AA52' ,
              cursor:row.original.status.toLowerCase()  === 'completed' ? 'not-allowed' : 'pointer',
              pointerEvents: row.original.status.toLowerCase() === 'completed' ? 'none' : 'auto',
              opacity: row.original.status.toLowerCase() === 'completed' ? 1 : 1,
           }}
            >
              phone
            </span>}
            {AppPermission.wa_view &&
              <img
                onClick={() => {
                  row.original.status !== 'completed' && wapopup(row.original) }}
                className={"wa_icon"}
                src={waicon}
                alt="test"
                style={{ 
                  filter: row.original.status.toLowerCase() === 'completed' ? 'grayscale(100%)' : 'none',
                  cursor:row.original.status.toLowerCase()  === 'completed' ? 'not-allowed' : 'pointer',
                  pointerEvents: row.original.status.toLowerCase() === 'completed' ? 'none' : 'auto',
                  opacity: row.original.status.toLowerCase() === 'completed' ? 1 : 1,
               }}
              />


            }

          </div>
        )
      },
    },
  ])
  useEffect(() => {
    if (stateValue.background.oncall) {
      setLead_id(stateValue.background.oncalllead[0].lead_id)
      setPhoneNumber(stateValue.background.oncalllead[0].phone_number)
    }
    fetchMeetings()
    fetchmeetingTitle()
    if(AppPermission.wa_view){
    dispatch(getTemplate()).then((res) => {
      if (res.data.length > 0) {

        setpopUpData_((set) => {
          set.field[0].option = res.data.map(e => {
            e.label = e.name
            e.value = e.id
            return e
          })
          return {
            ...set
          }
        })
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: "whatsapp Template",
      })
    })
    }
  }, [])
  useEffect(()=>{
    if( popupdata.open === false ){
      localStorage.removeItem('updating_date');
    }
  },[popupdata.open])
    // edit
    const handleSettingsClick = (value)=>{
      setDynamicHead(!value)
    }
    const saveDynamicPopup = (data) => {
      if(data===false){
        setDynamicHead(data)
        return
      }
      setDynamicHead(false)
      setloader(true)
      const payload ={
        fields:[data],
        module:'meeting',
        campaign:selected_campaign,
        template:campaigninfo.template_name
      }
      dispatch(AsterAgentTableUpdate(payload,user_id)).then((response)=>{
        if(response.data.statusCode === 200){
          getDynamicheaders()
        }
      }).catch((err)=>{
        setloader(false)
      })
    }
    useEffect(()=>{
      getDynamicheaders()
    },[])
    function getDynamicheaders(){
      setloader(true)
      const data = {
        user:user_id,
        campaign:selected_campaign,
        module:'meeting',
        template:campaigninfo.template_name
      }
      dispatch(AsterAgentTableField(data)).then((response)=>{
        if(response.data.statusCode === 200){
          const headerDefault = header[header.length-1];
          const defaultHeader =[headerDefault]
          const newData = response.data.data.map(item => {
            if(item.model === 'leadNumber'){
              return{
                masking:true,
                Header: item.label,
                accessor:item.model, 
                agent_table_show:item.agent_table_show
              }
            }else{
              return{
                masking:false,
                Header: item.label,
                accessor:item.model, 
                agent_table_show:item.agent_table_show
              }
            }
            });
            const updatedHeader = [ ...newData,...defaultHeader];
          setheader(updatedHeader) 
          setloader(false)
        }else{
          const headerDefault = header[header.length-1];
          const defaultHeader = [headerDefault];
          setheader(defaultHeader)
          setloader(false)
        }
      }).catch((err)=>{
        EventEmitter.emit('error-message-getter', {
          action: 'field',
          module: "Selected fields are removed Please select field.",
        })
        setloader(false)
      })
    }
    // edit  
  function fetchmeetingTitle() {
    dispatch(Meetingtitle()).then(function (res) {
      if (res.data.statusCode == 200) {
        let arr = []
        for (let i = 0; i < res.data.data.length; i++) {
          const e = res.data.data[i];
          arr.push({
            label: e.title,
            value: e.title,
            subtitle:e.sub_title
          })
        }
        arr.push({
          label: "others",
          value: "others"
          
        })
        setpopupdata((set) => {
          set.field[0].option = arr
          return set
        })
        setpopUpData((set) => {
          set.field[0].option = arr
          return set
        })

        setmeeting_title(arr)
      }
    })
  }
  function datetime() {
    var currentdate = new Date().toISOString()
    return currentdate
  }
  function closepopup_(data) {
    if (data.action === "cancel") {
      setpopUpData_((set) => {
        set.open = false
        return {
          ...set
        }
      })
    } else {
      let name

      dispatch(
        ContactFetch(
          `/contact/fetchsearch?number=${wadata.phone_number}`,
        ),
      )
        .then((res) => {
          if (res.data.statusCode == 200 && res.data.data.length > 0) {
            name = res.data.data[0].name
            sendtemp(data, name, 'contact', res.data.data[0].id)
          }
          else {
            let payload = {
              template_name: campaigninfo.template_name,
              type: campaigninfo.type,
              phone_number: wadata.phone_number,
              id: ""
            }
            dispatch(CustomformData(payload)).then((res) => {
              if (res.data.statusCode == 200) {
                name = res.data.data[0].others.first_name
                sendtemp(data, name, '_', res.data.data[0].others.lead_id)
              }
            }).catch((err) => {
              EventEmitter.emit('error-message-getter', {
                action: 'fetch',
                module: 'lead data',
              })
            })
          }
        }).catch(() => {
          EventEmitter.emit('error-message-getter', {
            action: 'fetch',
            module: "contact",
          })
        })

    }


  }
  function wapopup(data) {
    setwadata(data)
    setpopUpData_((set) => {
      set.open = true
      return {
        ...set
      }
    })
  }
  function datetime() {
    var currentdate = new Date()
    return currentdate
  }
  function sendtemp(data, name, module, id) {
    function find_(data_) {

      return data_.value === data.data[0].whatsapp_template;
    }
    let templatedata = {
      "action": "send-wa-template",
      "station": localStorage.getItem('extension'),
      "phone_number": wadata.phone_number,
      "sender_id": id,
      "sender_type": module === "contact" ? 1 : 2,
      "sender_name": name,
      "tenant_id": JSON.parse(localStorage.getItem("config")).data.tenant_code,
      "agent": localStorage.getItem("user"),
      "template": {
        "id": data.data[0].whatsapp_template,
        "type": "",
        "name": data.field[0].option.find(find_).name,
        "language": data.field[0].option.find(find_).lang
      }
    }
    if (data.action === "create") {
      dispatch(waTemplate(stateValue.background.websocketconnection, templatedata))
      setpopUpData_((set) => {
        set.open = false
        return {
          ...set
        }
      })
    }



  }
  const dialogopen = (data, id, date) => {
    setedit_mode(true)
    if (id) setEditId(id)
    setpopUpData((set) => {

      // for (let i = 0; i < set.field[0].option.length; i++) {
      // const e = set.field[0].option[i];
      // if (date.meeting_name === e.value) {
      // name = date.meeting_name
      // } else {
      // name = 'others'
      // }

      // }
      setpopUpData((set) => {
        for (let i = 0; i < set.field.length; i++) {
          if(set.field[i].model == "meetingphoneNumber"){
            set.field[i].placeholder = Format(date.leadNumber, { maskbool: true })
          }
        }
        return {
          ...set
        }
      })
      let name = set.field[0].option.find((e) => {
        if (e.value === date.meeting_name) {
          return e.value
        }
      })
      if (name === undefined) {
        name = 'others'
        set.field[1].show = true
        set.field[1].required = true
      } else {
        name = name.value
        set.field[1].show = false
        set.field[1].required = false
      }
      let subtitle=[]
      if(name!=='others'){
        for (let i = 0; i < popUpData.field[0].option.length; i++) {
          const element = popUpData.field[0].option[i];
          if(element.label===name){
           let subtitlearr=element.subtitle.split(',')
           if(subtitlearr[0] !== ''){
            set.field[2].show = true
            for (let j = 0; j < subtitlearr.length; j++) {
              const subtitleelement = subtitlearr[j];
              subtitle.push({
                label:subtitleelement,
                value:subtitleelement,
              })
              
             }
           }else{
            set.field[2].show = false
           }
          }
          
        }
      }
      set.open = data
      set.field[2].option = subtitle
      set.field[0].value = name
      set.field[2].value = date.meeting_sub_title
      set.field[1].value = date.meeting_name
      set.field[3].value = date.phoneNumber
      // set.field[3].value = date
      set.field[4].value = date.scheduledTime
      set.field[5].value = date.subject
      set.field[6].value = date.meetingID
      return {
        ...set,
      }
    })
  }
  const dialogOpenToCreate = (data) => {
    setPopUpDatatoCreate((set) => {
      set.open = data
      return {
        ...set,
      }
    })
  }
  const notify_bar = (data, action) => {
    setpopUpData1((set) => {
      set.open = data
      if (action == 'update') {
        set.Message = 'Update Sucessfully'
      } else {
        set.Message = 'Created Sucessfully'
        set.title = "Meeting Create "
      }
      return {
        ...set,
      }
    })
  }
  const closepopup = (data) => {
    if (data.data[0].name === 'others') {
      data.data[0].name = data.data[0].others
    }
    delete data.data[0].others
    delete data.data[0].meetingphoneNumber
    delete data.data[0].meetingID 
    if (data.action === 'create') {
      setshedulealart(false)
      dispatch(editMeeting(editId, data.data[0])).then(function (res) {
        if (res.data.statusCode == 200) {
          notify_bar(true, 'update')
          fetchMeetings("refresh")
          setpopUpData((data) => {
            data.open = false
            setshedulealart(false)
            return {
              ...data,
            }
          })
        }
        if (res.status == 204) {
          setshedulealart(true)

        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'update',
          module: 'meeting',
        })
      })


    } else {
      setpopUpData((data) => {
        data.open = false
        setshedulealart(false)
        return {
          ...data,
        }
      })
    }
  }
  const close_meetingpopup = (data,currentTime) => {
    if(currentTime === 'currentTimeUpdate'){  
      setpopupdata({ ...data })
      return
    }
    if (data.action === "create") {
      let payload = {
        meetingName: data.data[0]['name'] === 'others' ? data.data[0].others : data.data[0]['name'],
        meetingTilte: data.data[0]['comments'],
        meetingTime: data.data[0]['scheduleDate'],
        user_email: localStorage.getItem('user'),
        leadID: data.data[0]['id'].toString(),
        phoneNumber: data.data[0]['meetingphoneNumber'],
        meeting_sub_title: data.data[0]['meeting_sub_title'],
        campaign: selected_campaign,
        module: data.data[0]['module'],
        callerName: data.data[0]['meetingphoneNumber'],
      }
      dispatch(create_meeting(payload))
        .then(function (res) {
          if (res.data.statusCode == 201) {
            setedit_mode(false)
            isshow(false)
            fetchMeetings("refresh")
            setpopupdata((data) => {
              data.open = false
              return {
                ...data,
              }
            })
          }
          if (res.status == 204) {
            isshow(false)
            setshedulealart(true)
          }

        })
        .catch((err) => {

          EventEmitter.emit('error-message-getter', {
            action: 'create',
            module: 'meeting',
          })
        })
    } else {
      isshow(false)
      setpopupdata((data) => {
        setshedulealart(false)
        setedit_mode(false)
        data.open = false
        return {
          ...data,
        }
      })
      
    }

  }
  const closeCreatePopup = (data) => {
    if (data.action === 'create') {

      let selected_user = localStorage.getItem('user')
      let payload = {
        meetingTilte: data.data[0]['comments'],
        meetingTime: data.data[0]['scheduleDate'],
        user_email: selected_user,
        leadID: data.data[0]['phoneNumber'] === phoneNumber ? `${lead_id}` : "",
        phoneNumber: data.data[0]['phoneNumber'],
        campaign: selected_campaign,
      }

      dispatch(create_meeting(payload)).then(function (res) {
        if (res.data.statusCode == 201) {
          notify_bar(true, 'create')
          fetchMeetings("refresh")
        }
        if (res.status == 204) {
          setshedulealart(true)

        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'create',
          module: 'meeting',
        })
      })
      setPopUpDatatoCreate((data) => {
        setshedulealart(false)
        data.open = false
        return {
          ...data,
        }

      })
    } else {
      setPopUpDatatoCreate((data) => {
        data.open = false
        return {
          ...data, 
        }
      })
    }
  }
  const closeNotify = (data) => {
    setpopUpData1(data)
  }

  const MeetingView = (row, pageName, id) => {
    if (pageName === 'MeetingView') {
      navigate(`/meeting/${Encode(row.original.leadNumber)}/${id}`)
    }
  }
  const fetchMeetings = (search, Moredata) => {
    const user_id = localStorage.getItem('user')
    let query = {
      userID: user_id,
    }
    query.skip = skipdata.skip
    query.take = skipdata.take
    if (search !== 'mount' && search !== 'filter' && search !== 'refresh') {
      query.filter = search
    }
    if (search == 'filter') {
      query.fromDate = filterdata.from
      query.toDate = filterdata.to
      query.module = filterdata.module
    }
    if (search == "refresh") {
      query.skip = 0
      query.take = 20
      setfilterdata({})
      setSearch("")
      setinfinitScroll((set) => {
        set.more = true
        return {
          ...set,
        }
      })
    }
    query.campaign = selected_campaign;
    if (Moredata !== "Moredata") {
      setloader(true)
    }
    dispatch(fetch_meetings(query)).then(function (response) {
      setloader(false)
      let element = response.data.data
      let value = []

      if (response.status == 200) {
        setcount(response.data.totalCount)
        setskipdata((set) => {
          if (search !== "refresh") {
            if (set.take === 20) {
              set.skip = 20
              set.take = 10
            } else {
              set.skip = set.skip + 10
              set.take = 10
            }
          } else {
            set.skip = 20
            set.take = 10
          }

          return {
            ...set,
          }
        })
        console.log(element,'this is element')
        for (let i = 0; i < element.length; i++) {
          let obj = {}
          for (let key in element[i]) {
            if (element[i].hasOwnProperty(key)) {
              element[i][key]=element[i][key] === null ? "" :element[i][key];
              element[i][key] = element[i][key] === '0000-00-00 00:00:00' ? "" : element[i][key];
              if (typeof element[i][key] !== 'object') {
               obj[key] = reduceLength(Format(element[i][key]));
              }
          }
        }
          obj.all = element[i];
          value.push(obj)
        }
        console.log(value,'this is value 2')
        if (query.skip === 0 && searchdata !== '' || search === "refresh") {
          setMeetingDetail([].concat(value))
        } else {
          setMeetingDetail(meetingDetail.concat(value))
        }
      } else if (response.status == 204) {
        setMeetingDetail([].concat(value))
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'meeting',
      })
    })
  }
  const structureChange = () =>{
    if(header.length <= 4 || loader){
      return ''
    }
    return 'structureChange'
  }
  const filterdataapi = (filter) => {
    setinfinitScroll((set) => {
      set.more = true
      return {
        ...set,
      }
    })
    let query = {
      userID: user_id,
      fromDate: filter.from,
      toDate: filter.to,
      module: filter.module,
      status: filter.status,
      campaign: selected_campaign

    }
    setfilterdata(filter)
    query.skip = 0
    query.take = 20

    setloader(true)
    dispatch(fetch_meetings(query)).then(function (response) {
      setloader(false)
      if (response.status == 200) {
        setSearch('')
        setcount(response.data.totalCount)
        let element = response.data.data
        let value = []
        setskipdata((set) => {
          set.skip = 20
          set.take = 10
          return {
            ...set,
          }
        })
        for (let i = 0; i < element.length; i++) {
          let obj = {}
          for (let key in element[i]) {
            if (element[i].hasOwnProperty(key)) {
              element[i][key]=element[i][key] === null ? "" :element[i][key];
              element[i][key] = element[i][key] === '0000-00-00 00:00:00' ? "" : element[i][key];
              if (typeof element[i][key] !== 'object') {
               obj[key] = reduceLength(Format(element[i][key]));
              }
          }
        }
        
          obj.all = element[i];
          value.push(obj)
        }
        console.log(value,'this is value 2')
        setMeetingDetail([].concat(value))
      } else {
        setMeetingDetail([])
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'meeting',
      })
    })
  }
  function isValidDateString(str) {
    return !isNaN(Date.parse(str));
  }
  const reduceLength = (value) =>{
    if (typeof value === 'string' && isValidDateString(value)) {
      let datetime = new Date(value)
      let dd = datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate()
      let mm = datetime.getMonth() + 1 < 10 ? `0${datetime.getMonth() + 1}` : datetime.getMonth() + 1
      let hr = datetime.getHours() < 10 ? `0${datetime.getHours()}` : datetime.getHours()
      let min = datetime.getMinutes() < 10 ? `0${datetime.getMinutes()}` : datetime.getMinutes()
      value = datetime.getFullYear() + `-${mm}-${dd} ${hr}:${min}`
    }
    else if(typeof value=== "string" && value.length >= 17 ){
      value = value.substring(0, 17)+'...';
      return value
    }
    return value
  }
  let fetchMoreData = () => {
    if (meetingDetail.length >= count) {
      setinfinitScroll((set) => {
        set.more = false
        return {
          ...set,
        }
      })
    } else {
      setTimeout(() => {
        if (searchdata !== '') {
          fetchMeetings(searchdata, "Moredata")
        }
        if (Object.keys(filterdata).length > 0) {
          fetchMeetings('filter', "Moredata")
        } else {
          fetchMeetings('mount', "Moredata")
        }
      }, 1000)
    }
  }

  function searchMeetings(e) {
    setSearch(e)
    setinfinitScroll((set) => {
      set.more = true
      return {
        ...set,
      }
    })
    let query = {}
    query.skip = 0
    query.take = 20
    query.filter = e
    query.userID = user_id
    query.campaign = selected_campaign;
    setloader(true)
    dispatch(fetch_meetings(query)).then(function (response) {
      setloader(false)
      let element = response.data.data
      let value = []

      if (response.status == 200) {
        setfilterdata({})
        setcount(response.data.totalCount)
        setskipdata((set) => {
          set.skip = 20
          set.take = 10
          return {
            ...set,
          }
        })
        for (let i = 0; i < element.length; i++) {
          let obj = {}
          for (let key in element[i]) {
            if (element[i].hasOwnProperty(key)) {
              element[i][key]=element[i][key] === null ? "" :element[i][key];
              element[i][key] = element[i][key] === '0000-00-00 00:00:00' ? "" : element[i][key];
              if (typeof element[i][key] !== 'object') {
               obj[key] = reduceLength(Format(element[i][key]));
              }
          }
        }
          obj.all = element[i];
          value.push(obj)

        }
        console.log(value,'this is value 1')
        setMeetingDetail([].concat(value))
      } else if (response.status == 204) {
        setMeetingDetail([].concat(value))
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'meeting',
      })
    })
  }

  const refresh = () => {

    fetchMeetings('refresh')
  }

  function clickcall(data) {

    let readystate = localStorage.getItem("selectedPause")
    if (readystate == "available") {
      let payload = {
        state: stateValue.background.websocketconnection,
        value: data.original.leadNumber.toString()
      }
      dispatch(callmodule({
        id: data.original.meetingID.toString(),
        module: "meeting",
        phone_no: data.original.leadNumber.toString()
      }))
      dispatch(Outbound(payload.state, payload.value, { meeting: true, meetingid: data.original.meetingID.toString() }))

    } else {

      EventEmitter.emit('error-message-getter', {
        action: 'ready',
        module: "Agent Not ready",
      })
    }


  }
  const meetingdialogopen = (data) => {
    setpopupdata((set) => {
      set.open = data
      return {
        ...set,
      }
    })
  }
  const isshow = (data,value) => {
    let subtitle=[]
    if(value!=='others'){
      for (let i = 0; i < popupdata.field[0].option.length; i++) {
        const element = popupdata.field[0].option[i];
        if(element.label===value){
         let subtitlearr=element.subtitle.split(',')
         for (let j = 0; j < subtitlearr.length; j++) {
          const subtitleelement = subtitlearr[j];
          subtitle.push({
            label:subtitleelement,
            value:subtitleelement,
          })
          
         }
        }
        
      }
    }
    setpopupdata((set) => {
      set.field[1].show = data
      set.field[1].required = data
      if(value=='others'){
        set.field[2].show = false
      }else{
        
        if(subtitle.length>0 && subtitle[0].label !== '' &&  subtitle[0].value !== ''){
          set.field[2].show = true
        }else{
          set.field[2].show = false
        }
        
      }
      set.field[2].option = subtitle
      return set
    })
    setpopUpData((set) => {
      set.field[1].show = data
      set.field[1].required = data
      if(value=='others'){
        set.field[2].show = false
      }else{
       
        if(subtitle.length>0 && subtitle[0].label !== '' &&  subtitle[0].value !== ''){
          set.field[2].show = true
        }else{
          set.field[2].show = false
        }
      }
      set.field[2].option = subtitle
     

      return set
    })

  }
  return (
    <div className="page-main-container page-main-container-mobile">
      <DynamicField
      module={'meeting'}
      dynamicHead={dynamicHead}
      saveDynamicPopup={saveDynamicPopup}
      />
      <Dialogbox
        fields={close_meetingpopup}
        shedulealart={shedulealart}
        value={popupdata}
        defaultclass={'dialog-body-default'}
        isshow={isshow} 
      />
      <Dialogbox
        fields={closepopup_}
        value={popUpData_}
        className="dialog-body-default1"
        // cancel={{ display: 'none' }}
        save={'disabled'}
      />
      <Dialogbox
        edit_mode={edit_mode}
        fields={closepopup}
        value={popUpData}
        isshow={isshow}
        className="dialog-body-default1"
        shedulealart={shedulealart}
        meetingDetail={meetingDetail}
      />
      <Dialogbox
        fields={closeCreatePopup}
        value={popUpDatatoCreate}
        className="dialog-body-default1"
      />
      <Dialogbox
        fields={closeNotify}
        value={popUpData1}
        className="dialog-body-default1"
      />
      <div className="scroll-lft" md={8}>
        <div className="lead-header">
          <div className="lead-header1">
            <div className="route-header lead-header1-lft" style={{ cursor: 'default'}}> Meetings</div>
            {AppPermission.meeting_search && <div className="lead-search">
              <span className="material-icons lead-search-icon">search</span>
              <Form.Control
                type="text"
                placeholder="Search"
                style={{
                  border: '1px solid #e9ecef',
                  backgroundColor: '#fff',
                  padding: 10,
                  'padding-left': '40px',
                }}
                value={searchdata}
                onChange={(e) => searchMeetings(e.target.value)}
              />
            </div>}
          </div>
      <div className="lead-header2">
      {AppPermission.meeting_refresh && 
            <OverlayTrigger
            placement={'bottom'}
            overlay={<Tooltip>Refresh</Tooltip>}
          >
            <div
              onClick={refresh}
              className="lead-table-button material-icons"
            >
              refresh
            </div></OverlayTrigger>}
          <OverlayTrigger
                placement={'bottom'}
                overlay={<Tooltip>Header settings</Tooltip>}
              >
           <span
             className="material-icons settings lead-table-button"
             style={{ color: '#3e98c7' }}
             onClick={() => handleSettingsClick(dynamicHead)}
            >
            settings
           </span>
        </OverlayTrigger>
            {AppPermission.meeting_filter && 
            <OverlayTrigger
            placement={'bottom'}
            overlay={<Tooltip>Filter</Tooltip>}
          >
            <div>
            <Filters filterdataapi={filterdataapi} type={'meeting'} /></div>
            </OverlayTrigger>}
            {AppPermission.create_meeting && 
            <OverlayTrigger
            placement={'bottom'}
            overlay={<Tooltip>Add</Tooltip>}
          >
            <div
              onClick={() => {
                meetingdialogopen(true)
              }}
              className="lead-table-button material-icons"
            >
              add_circle
            </div></OverlayTrigger>}
          </div>
        </div>
        <div className="lead-body">
          <div
          className={structureChange()}
            id="meetingsDiv"
            style={{
              height: '100%',
              overflow: 'auto',
              color: 'grey',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <InfiniteScroll
              dataLength={meetingDetail.length}
              next={fetchMoreData}
              hasMore={infinitScroll.more}
              scrollableTarget="meetingsDiv"
              style={{
                overflow: "initial"
              }}
            >
              <MeetingTable
                loader={loader}
                header={header}
                leaddetail={meetingDetail}
                type="meet"
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Meeting